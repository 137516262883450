<h1 mat-dialog-title>{{"labels.heading.Edit Note" | translate}}</h1>
<div>
  <form #formRef="ngForm" [formGroup]="noteForm">
    <mat-form-field>
      <textarea formControlName="note" matInput placeholder="Write a note ...."></textarea>
    </mat-form-field>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>{{"labels.buttons.Cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="noteForm.pristine"
        [mat-dialog-close]="{ editForm: noteForm }">{{"labels.buttons.Confirm" | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
