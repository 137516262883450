<div class="container">

  <mat-card>

    <form [formGroup]="roleForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.Name' | translate }}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="roleForm.controls.name.hasError('required')">
              {{ 'labels.inputs.Role Name' | translate }}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.Description' | translate }}</mat-label>
            <textarea matInput required formControlName="description"></textarea>
            <mat-error *ngIf="roleForm.controls.description.hasError('required')">
              {{ 'labels.inputs.Role Description' | translate }}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
          <button mat-raised-button color="primary" [disabled]="!roleForm.valid">{{'labels.buttons.Submit' | translate}}</button>
        </mat-card-actions>

      </form>

    </mat-card>

</div>
