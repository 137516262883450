<form [formGroup]="createClientForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Office" | translate}}</mat-label>
      <mat-select required formControlName="officeId">
        <mat-option *ngFor="let office of officeOptions" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createClientForm.controls.officeId.hasError('required')">
        {{"labels.inputs.Office" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="23%">
      <mat-label>{{"labels.inputs.Legal Form" | translate}}</mat-label>
      <mat-select required formControlName="legalFormId">
        <mat-option *ngFor="let legalForm of legalFormOptions" [value]="legalForm.id">
          {{ legalForm.value | translateKey:'inputs'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="23%">
      <mat-label>{{"labels.inputs.External Id" | translate}}</mat-label>
      <input matInput formControlName="externalId">
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('fullname')" fxFlex="48%">
      <mat-label>{{ 'labels.inputs.' + getDateLabel(createClientForm.value.legalFormId, ['Name', 'Entity Name']) | translate }}</mat-label>
      <input matInput required formControlName="fullname">
      <mat-error *ngIf="createClientForm.controls.fullname.hasError('required')">
        {{"labels.inputs.Client name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.fullname.hasError('pattern')">
        {{"labels.inputs.Client name" | translate}} <strong>{{"labels.inputs.cannot" | translate}}</strong> {{"labels.commons.begin with a special character or number" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('firstname')" fxFlex="48%">
      <mat-label>{{"labels.inputs.First Name" | translate}}</mat-label>
      <input matInput required formControlName="firstname">
      <mat-error *ngIf="createClientForm.controls.firstname.hasError('required')">
        {{"labels.inputs.Client first name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.firstname.hasError('pattern')">
        {{"labels.inputs.Client first name" | translate}} <strong>{{"labels.inputs.cannot" | translate}}</strong> {{"labels.commons.begin with a special character or number" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('middlename')" fxFlex="48%">
      <mat-label>{{"labels.inputs.Middle Name" | translate}}</mat-label>
      <input matInput formControlName="middlename">
      <mat-error *ngIf="createClientForm.controls.middlename.hasError('pattern')">
        {{"labels.inputs.Client middle name" | translate}} <strong>{{"labels.inputs.cannot" | translate}}</strong> {{"labels.commons.begin with a special character or number" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('lastname')" fxFlex="48%">
      <mat-label>{{"labels.inputs.Last Name" | translate}}</mat-label>
      <input matInput required formControlName="lastname">
      <mat-error *ngIf="createClientForm.controls.lastname.hasError('required')">
        {{"labels.inputs.Client last name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.lastname.hasError('pattern')">
        {{"labels.inputs.Client last name" | translate}} <strong>{{"labels.inputs.cannot" | translate}}</strong> {{"labels.commons.begin with a special character or number" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-divider></mat-divider>

    <mat-form-field fxFlex="48%" (click)="dateOfBirthDatePicker.open()">
      <mat-label>{{ 'labels.inputs.' + getDateLabel(createClientForm.value.legalFormId, ['Date of Birth', 'Incorporation Date']) | translate }}</mat-label>
      <input matInput [max]="maxDate" [matDatepicker]="dateOfBirthDatePicker" formControlName="dateOfBirth">
      <mat-datepicker-toggle matSuffix [for]="dateOfBirthDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dateOfBirthDatePicker></mat-datepicker>
    </mat-form-field>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" formGroupName="clientNonPersonDetails"
      *ngIf="createClientForm.contains('clientNonPersonDetails')">

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.Constitution" | translate}}</mat-label>
        <mat-select formControlName="constitutionId" required>
          <mat-option *ngFor="let constitution of constitutionOptions" [value]="constitution.id">
            {{ constitution.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.Main Business Line" | translate}}</mat-label>
        <mat-select formControlName="mainBusinessLineId">
          <mat-option *ngFor="let business of businessLineOptions" [value]="business.id">
            {{ business.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48%" (click)="incorpValidityTillDateDatePicker.open()">
        <mat-label>{{"labels.inputs.Incorporation Validity Till Date" | translate}}</mat-label>
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="incorpValidityTillDateDatePicker" formControlName="incorpValidityTillDate">
        <mat-datepicker-toggle matSuffix [for]="incorpValidityTillDateDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #incorpValidityTillDateDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.Incorporation Number" | translate}}</mat-label>
        <input matInput formControlName="incorpNumber">
      </mat-form-field>

      <mat-form-field fxFlex="98%">
        <mat-label>{{"labels.inputs.Remarks" | translate}}</mat-label>
        <textarea matInput formControlName="remarks" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
      </mat-form-field>

    </div>

    <mat-form-field *ngIf="createClientForm.value.legalFormId === 1" fxFlex="48%">
      <mat-label>{{"labels.inputs.Gender" | translate}}</mat-label>
      <mat-select formControlName="genderId">
        <mat-option *ngFor="let gender of genderOptions" [value]="gender.id">
          {{ gender.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Staff" | translate}}</mat-label>
      <mat-select formControlName="staffId">
        <mat-option *ngFor="let staff of staffOptions" [value]="staff.id">
          {{ staff.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox *ngIf="createClientForm.value.legalFormId === 1" fxFlex="48%" labelPosition="before" formControlName="isStaff" class="margin-v">
      {{"labels.inputs.Is staff" | translate}}?
    </mat-checkbox>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Mobile No" | translate}}</mat-label>
      <input matInput type="number" formControlName="mobileNo">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Email Address" | translate}}</mat-label>
      <input matInput formControlName="emailAddress">
      <mat-error *ngIf="createClientForm.controls.emailAddress.errors?.email">
        {{"error.Email not valid" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Client Type" | translate}}</mat-label>
      <mat-select formControlName="clientTypeId">
        <mat-option *ngFor="let clientType of clientTypeOptions" [value]="clientType.id">
          {{ clientType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.Client Classification" | translate}}</mat-label>
      <mat-select formControlName="clientClassificationId">
        <mat-option *ngFor="let clientClassification of clientClassificationTypeOptions" [value]="clientClassification.id">
          {{ clientClassification.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%" (click)="submittedOnDatePicker.open()">
      <mat-label>{{"labels.inputs.Submitted On" | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker" formControlName="submittedOnDate" required>
      <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #submittedOnDatePicker></mat-datepicker>
    </mat-form-field>

    <br />

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="active" class="margin-v">
      {{"labels.inputs.Active" | translate}}?
    </mat-checkbox>

    <mat-form-field *ngIf="createClientForm.contains('activationDate')" fxFlex="48%" (click)="activatedOnDatePicker.open()">
      <mat-label>{{"labels.inputs.Activation Date" | translate}}</mat-label>
      <input matInput [min]="createClientForm.value.submittedOnDate" [max]="maxDate" [matDatepicker]="activatedOnDatePicker" required
        formControlName="activationDate">
      <mat-datepicker-toggle matSuffix [for]="activatedOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #activatedOnDatePicker></mat-datepicker>
      <mat-error *ngIf="createClientForm.controls.activationDate.hasError('required')">
        {{"labels.inputs.Activation Date" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="addSavings" class="margin-v">
      {{"labels.inputs.Open Savings Account" | translate}}?
    </mat-checkbox>

    <mat-form-field *ngIf="createClientForm.contains('savingsProductId')" fxFlex="48%">
      <mat-label>{{"labels.inputs.Savings Product" | translate}}</mat-label>
      <mat-select required formControlName="savingsProductId">
        <mat-option *ngFor="let product of savingProductOptions" [value]="product.id">
          {{ product.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createClientForm.controls.savingsProductId.hasError('required')">
        {{"labels.inputs.Savings Product" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{"labels.buttons.Previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.Next" | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
