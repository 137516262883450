<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <span *mifosxHasPermission="'CREATE_GLACCOUNT'">
    <button mat-raised-button color="primary" *ngIf="glAccount.usage.value === 'HEADER'" [routerLink]="['/accounting/chart-of-accounts/gl-accounts/create']" [queryParams]="{ parent: glAccount.id, accountType: glAccount.type.id }">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      {{"labels.buttons.Subledger Account" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="primary" [routerLink]="['edit']">
      <fa-icon icon="edit" class="m-r-10"></fa-icon>
      {{"labels.buttons.Edit" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="warn" *ngIf="!glAccount.disabled" (click)="changeGlAccountState()">
      <fa-icon icon="lock" class="m-r-10"></fa-icon>
      {{"labels.buttons.Disable" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="accent" *ngIf="glAccount.disabled" (click)="changeGlAccountState()">
      <fa-icon icon="lock-open" class="m-r-10"></fa-icon>
      {{"labels.buttons.Enable" | translate}}
    </button>
  </span>
  <button mat-raised-button color="warn" (click)="deleteGlAccount()" *mifosxHasPermission="'DELETE_GLACCOUNT'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{"labels.buttons.Delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Account Type" | translate}}
        </div>

        <div fxFlex="50%">
          {{ 'labels.inputs.accounting.' + glAccount.type?.value | translate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.GL Code" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.glCode }}
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.parent">
          {{"labels.inputs.Parent Account Name" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.parent">
          <a class="tab-link" [routerLink]="['/accounting/chart-of-accounts/gl-accounts/view/' + glAccount.parent.id]">
            <mifosx-gl-account-display [glAccount]="glAccount.parent"></mifosx-gl-account-display>
          </a>
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.tagId.id">
           {{"labels.inputs.Tag" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.tagId.id">
          {{ glAccount.tagId.name }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Account Usage" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.usage?.value }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Manual Entries Allowed" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.manualEntriesAllowed | yesNo }}
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.description">
          {{"labels.inputs.Description" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.description">
          {{ glAccount.description }}
        </div>

      </div>

    </mat-card-content>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" color="primary" mat-raised-button (click)="goBack()">{{  "labels.buttons.Back" | translate}}</button>
    </mat-card-actions>
  </mat-card>

</div>
