<div class="container">

  <mat-card>

    <form [formGroup]="taxComponentForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{'labels.inputs.Name' | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')">
              {{'labels.inputs.Name' | translate}} {{'labels.commons.is' | translate}}
              <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.Percentage' | translate}}</mat-label>
            <input type="number" matInput required formControlName="percentage">
            <mat-error *ngIf="taxComponentForm.controls.percentage.hasError('required')">
              {{'labels.inputs.Percentage ' | translate}} {{'labels.commons.is' | translate}}
              <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="taxComponentForm.controls.percentage.hasError('pattern') ||
            taxComponentForm.controls.percentage.hasError('max')">
              {{'labels.inputs.Percentage' | translate}} <strong>{{'labels.commons.should' | translate}}</strong> {{'labels.commons.be larger than 0 and at most 100' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.Debit Account Type' | translate}}</mat-label>
            <mat-select formControlName="debitAccountType">
              <mat-option *ngFor="let debitAccountType of debitAccountTypeData" [value]="debitAccountType.id">
                {{ debitAccountType.value | translateKey:'inputs.accounting' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mifosx-gl-account-selector *ngIf="debitAccountData.length > 0"
            [inputFormControl]="taxComponentForm.controls.debitAcountId"
            [glAccountList]="debitAccountData" [required]="false" [inputLabel]="'Credit Account'">
          </mifosx-gl-account-selector>

          <mat-form-field>
            <mat-label>{{'labels.inputs.Credit Account Type' | translate}}</mat-label>
            <mat-select formControlName="creditAccountType">
              <mat-option *ngFor="let creditAccountType of creditAccountTypeData" [value]="creditAccountType.id">
                {{ creditAccountType.value | translateKey:'inputs.accounting' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mifosx-gl-account-selector *ngIf="creditAccountData.length > 0"
            [inputFormControl]="taxComponentForm.controls.creditAcountId"
            [glAccountList]="creditAccountData" [required]="false" [inputLabel]="'Credit Account'">
          </mifosx-gl-account-selector>

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>{{'labels.inputs.Start Date' | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required
              formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="taxComponentForm.controls.startDate.hasError('required')">
              {{'labels.inputs.Start Date' | translate}} {{'labels.commons.is' | translate}}
              <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!taxComponentForm.valid"
          *mifosxHasPermission="'CREATE_TAXCOMPONENT'">{{'labels.buttons.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
