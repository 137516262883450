<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateCharge class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_FUND'">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      {{'labels.buttons.Create Fund' | translate }}
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #chargesTable class="mat-elevation-z8 table-container">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.name' | translate }} </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.name }} </td>
      </ng-container>

      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.External Id' | translate }} </th>
        <td mat-cell *matCellDef="let charge">
          <mifosx-external-identifier externalId="{{charge.externalId}}"></mifosx-external-identifier>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>


<ng-template #templateFundFormRef let-popover="popover">
  <h2>{{ 'labels.heading.Manage Funds' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.Used Add Fund' | translate }}: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67141736/Manage+Funds" target="_blank">{{ 'labels.inputs.Manage Funds' | translate }}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();">{{'labels.buttons.Add Funds' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

