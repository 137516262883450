<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <!-- TODO: Update to show only Activate/Inactivate button at a time according to status of user. -->
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="changeUserPassword()">
    <fa-icon icon="cog" class="m-r-10"></fa-icon>
    {{'labels.buttons.Change Password' | translate}}
  </button>
  <button mat-raised-button color="accent">
    <fa-icon icon="lock-open" class="m-r-10"></fa-icon>
    {{'labels.buttons.Activate' | translate}}
  </button>
  <button mat-raised-button color="warn">
    <fa-icon icon="lock" class="m-r-10"></fa-icon>
    {{'labels.buttons.Deactivate' | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="100%" class="header">
          <fa-icon icon="circle" size="lg" matTooltip="{{ user.status }}" matTooltipPosition="above"></fa-icon>
          &nbsp;&nbsp;
          {{'labels.inputs.ID' | translate}}: {{ user.id }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.User Name' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.username }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.First Name' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.firstName }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Last Name' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.lastName }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Gender' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.gender }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Date of Birth' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.dateOfBirth }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Email' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.email }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Mobile' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.mobile }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Office' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.office }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Staff' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.staff }}
        </div>

        <div fxFlex="50%" class="header">
          {{'labels.inputs.Activation Date' | translate}}
        </div>

        <div fxFlex="50%">
          {{ user.activationDate }}
        </div>

      </div>

    </mat-card-content>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" color="primary" mat-raised-button [routerLink]="['../../']">{{'labels.buttons.Back' | translate}}</button>
    </mat-card-actions>
  </mat-card>

</div>
