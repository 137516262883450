<mat-card class="container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box m-r-30">
      <mat-form-field class="search-box">
        <input matInput placeholder="Search by client name, external Id, mobile" class="search-box" (keydown.enter)="search($event.target.value)">
      </mat-form-field>
    </div>
    <div class="filter-box" *ngIf="existsClientsToFilter">
      <mat-checkbox #showClosedAccounts labelPosition="after" class="m-r-10">{{"labels.inputs.Show Closed Accounts" | translate}}
      </mat-checkbox>
    </div>
    <div class="action-button" fxLayoutGap="25px">
      <button mat-raised-button color="primary" [routerLink]="['../', 'organization', 'bulk-import', 'Clients']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="download" class="m-r-10"></fa-icon>{{"labels.buttons.Import Client" | translate}}
      </button>
      <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>{{"labels.buttons.Create Client" | translate}}
      </button>
    </div>
  </div>

  <div [hidden]="!existsClientsToFilter">
    <div *ngIf="isLoading">
      <div class="loading"></div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)">

      <!-- Name Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.name" | translate}} </th>
        <td mat-cell *matCellDef="let row" [routerLink]="[row.id,'general']"> {{row.displayName}} </td>
      </ng-container>

      <!-- Account no Column -->
      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.Account No" | translate}}</th>
        <td mat-cell *matCellDef="let row">
          <mifosx-account-number display="left" accountNo="{{row.accountNumber}}"></mifosx-account-number>
        </td>
      </ng-container>

      <!-- External ID Column -->
      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.External Id" | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <mifosx-external-identifier display="left" externalId="{{row.externalId}}"></mifosx-external-identifier>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Status" | translate}} </th>
        <td mat-cell *matCellDef="let row">
          <div [ngClass]="row.status.code | statusLookup">
            <fa-icon icon="stop"></fa-icon>
            <span class="m-l-10 status">
              {{ row.status.value | translate}}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Office Name" | translate}} </th>
        <td mat-cell *matCellDef="let row"> {{row.officeName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row">
      </tr>
    </table>

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 50, 100]" [length]="totalRows" (page)="pageChanged($event)"
      showFirstLastButtons></mat-paginator>
  </div>

  <div class="alert" [hidden]="!notExistsClientsToFilter">
    <div class="message">
      <i class="fa fa-exclamation-circle alert-check"></i>
      {{"labels.text.No client was found" | translate}}
    </div>
  </div>
</mat-card>
