<form [formGroup]="savingProductDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Product Name' | translate}}</mat-label>
      <input matInput formControlName="name" matTooltip="{{ 'tooltips.A unique identifier for the saving product' | translate}}" required>
      <mat-error>
        {{'labels.inputs.Product Name' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Short Name' | translate}}</mat-label>
      <input matInput formControlName="shortName" matTooltip="{{ 'tooltips.A unique identifier for the saving product' | translate}}" maxlength="4" required>
      <mat-error>
        {{'labels.inputs.Short Name' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="98%">
      <mat-label>{{'labels.inputs.Description' | translate}}</mat-label>
      <textarea matInput matTooltip="{{ 'tooltips.Provides additional information' | translate}}" formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{'labels.buttons.Previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{'labels.buttons.Next' | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
