<div class="tab-container mat-typography">

  <h3>{{"labels.heading.Group Details" | translate }}</h3>

  <div fxLayout="row" fxLayoutGap="32px" class="group-details-container m-b-30">
    <p>
      {{"labels.inputs.Active Client Loans" | translate }}: {{groupSummary?.activeClientLoans}}<br/>
      {{"labels.inputs.Active Client Borrowers" | translate }}: {{groupSummary?.activeClientBorrowers}}<br/>
      {{"labels.inputs.Active Overdue Client Loans" | translate }}: {{groupSummary?.overdueClientLoans}}<br/>
    </p>
    <p>
      {{"labels.inputs.Active Group Loans" | translate }}: {{groupSummary?.activeGroupLoans}}<br/>
      {{"labels.inputs.Active Group Borrowers" | translate }}: {{groupSummary?.activeGroupBorrowers}}<br/>
      {{"labels.inputs.Active Overdue Group Loans" | translate }}: {{groupSummary?.overdueGroupLoans}}<br/>
    </p>
    <p>
      {{"labels.inputs.Active Clients" | translate }}: {{groupSummary?.activeClients}}<br/>
    </p>
  </div>

  <!-- Client Members Table -->
  <div *ngIf="groupClientMembers">

    <h3>{{"labels.heading.Client Members" | translate }}</h3>

    <table mat-table [dataSource]="groupClientMembers"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.name" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.displayName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate }}. </th>
        <td mat-cell *matCellDef="let element"> {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Office">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Office" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.officeName}} </td>
      </ng-container>

      <ng-container matColumnDef="JLG Loan Application">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.JLG Loan Application" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <div class="m-l-30" *ngIf="element.status.code !== 'clientStatusType.closed'">
            <button class="account-action-button" mat-raised-button color="primary">
              <i class="fa fa-plus" matTooltip="{{ 'tooltips.New Loan Application' | translate }}"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="clientMemberColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: clientMemberColumns;" [routerLink]="['/clients', row.id, 'loans-accounts', 'create']" class="select-row"></tr>

    </table>

  </div>

  <div *ngIf="loanAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{"labels.heading.Loan Accounts" | translate }}</h3>
      </div>
      <div *ngIf="(loanAccounts|accountsFilter:'loan':'closed').length" class="action-button m-b-10">
        <button mat-raised-button class="f-right" color="primary"
      (click)="toggleLoanAccountsOverview()">{{showClosedLoanAccounts?'View Active Accounts':'View Closed Accounts'}}</button>
      </div>
    </div>

    <!-- Open Loan Accounts Table -->
    <table *ngIf="!showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate }}. </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"
            [matTooltip]="element.inArrears?'Active Overdue':element.status.value"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Loan Account" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Original Loan" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.originalLoan}} </td>
      </ng-container>

      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Loan Balance" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Amount Paid" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.amountPaid}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Type" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value==='Individual')?'fa-user':'fa-group'"
          [matTooltip]="(element.loanType.value==='Individual')?'Individual':'Group'"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Actions" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-dollar" matTooltip="{{ 'tooltips.Make Repayment' | translate }}"></i>
          </button>
          <button *ngIf="element.status.pendingApproval" class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-check" matTooltip="{{ 'tooltips.Approve' | translate }}"></i>
          </button>
          <button *ngIf="!element.status.pendingApproval && !element.status.active && !element.status.overpaid"
            class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-flag" matTooltip="{{ 'tooltips.Disburse' | translate }}"></i>
          </button>
          <button *ngIf="!element.status.pendingApproval && !element.status.active && element.status.overpaid"
            class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-exchange" matTooltip="{{ 'tooltips.Transfer Funds' | translate }}"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openLoansColumns;" [routerLink]="['../', 'loans-accounts', row.id, 'general']"></tr>

    </table>

    <!-- Closed Loan Accounts Table-->
    <table *ngIf="showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan':'closed'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate }}. </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"
            [matTooltip]="element.inArrears?'Active Overdue':element.status.value"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Loan Account" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Original Loan" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate  | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Loan Balance" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Amount Paid" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Type" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value=== 'Individual')?'fa-user':'fa-group'"
          [matTooltip]="(element.loanType.value==='Individual')?'Individual':'Group'"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Closed Date" | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate  | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedLoansColumns;" [routerLink]="['../', 'loans-accounts', row.id, 'general']"></tr>

    </table>

  </div>

  <div *ngIf="gsimAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{"labels.heading.GSIM Account Overview" | translate }}</h3>
      </div>
    </div>

    <!-- GSIM Accounts Table -->
    <table mat-table [dataSource]="gsimAccounts"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="GSIM Id">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.GSIM Id" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{element.gsimId}} </td>
      </ng-container>

      <ng-container matColumnDef="Account Number">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account Number" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="Product">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Product" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.childGSIMAccounts[0].productName}}</td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Balance" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.parentBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Status" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.savingsStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="gsimAccountsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: gsimAccountsColumns;" [routerLink]="['../', 'savings-accounts', 'gsim-account', row.accountNumber]"></tr>

    </table>

  </div>

  <div *ngIf="glimAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{"labels.heading.GLIM Loans Account Overview" | translate }}</h3>
      </div>
    </div>

    <!-- GLIM Loans Accounts Table -->
    <table mat-table [dataSource]="glimAccounts"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="GLIM Id">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.GLIM Id" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{element.glimId}} </td>
      </ng-container>

      <ng-container matColumnDef="Account Number">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account Number" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="Product">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Product" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.childGLIMAccounts[0].productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Original Loan" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.parentPrincipalAmount}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Status" | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.loanStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="glimAccountsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: glimAccountsColumns;" [routerLink]="['../', 'loans-accounts', 'glim-account', row.glimId]"></tr>

    </table>

  </div>

  <div *ngIf="savingAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{"labels.heading.Saving Accounts" | translate }}</h3>
      </div>
      <div class="action-button m-b-10">
        <button *ngIf="(savingAccounts|accountsFilter:'saving':'closed').length" mat-raised-button class="f-right" color="primary"
        (click)="toggleSavingAccountsOverview()">{{showClosedSavingAccounts?'View Active Accounts':'View Closed Accounts'}}</button>
      </div>
    </div>

    <!-- Open Savings Accounts Table -->
    <table *ngIf="!showClosedSavingAccounts" mat-table [dataSource]="savingAccounts|accountsFilter:'saving'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate }}. </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Saving Account" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Last Active">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Last Active" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate  | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Balance" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Actions" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
            <i class="fa fa-arrow-up" matTooltip="{{ 'tooltips.Deposit' | translate }}"></i>
          </button>
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
            <i class="fa fa-arrow-down" matTooltip="{{ 'tooltips.Withdraw' | translate }}"></i>
          </button>
          <button *ngIf="element.status.submittedAndPendingApproval" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Approve']">
            <i class="fa fa-check" matTooltip="{{ 'tooltips.Approve' | translate }}"></i>
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Undo Approval']">
            <i class="fa fa-undo" matTooltip="{{ 'tooltips.Undo Approval' | translate }}"></i>
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Activate']">
            <i class="fa fa-check-circle" matTooltip="{{ 'tooltips.Activate' | translate }}"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSavingsColumns;" [routerLink]="['../', 'savings-accounts', row.id, 'transactions']"></tr>

    </table>

    <!-- Closed Saving Accounts Table -->
    <table *ngIf="showClosedSavingAccounts" mat-table [dataSource]="savingAccounts|accountsFilter:'saving':'closed'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate }}. </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Saving Account" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Closed Date" | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate  | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSavingsColumns;" [routerLink]="['../', 'savings-accounts', row.id, 'transactions']"></tr>

    </table>

  </div>

</div>
