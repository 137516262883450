<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_TELLER'">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    {{ 'labels.buttons.Create Teller' | translate }}
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Branch' | translate }} </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Teller Name' | translate }} </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.name }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Status' | translate }} </th>
        <td mat-cell *matCellDef="let teller">
          <div [className]="teller.status === 'ACTIVE' ? 'true' : 'false'">
            <fa-icon matTooltip="{{ teller.status === 'ACTIVE' ? ( 'labels.inputs.Active' | translate ) : ('labels.catalogs.Inactive' | translate) }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Started On' | translate }} </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.startDate  | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.Actions' | translate }} </th>
        <td mat-cell *matCellDef="let teller" (click)="$event.stopPropagation()">
          <button mat-button color="primary" [routerLink]="[teller.id,'cashiers']">
            <fa-icon icon="eye" class="m-r-10"></fa-icon>{{ 'labels.buttons.View Cashiers' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
