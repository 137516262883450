<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">

      <div fxFlex="50%">

        <mat-nav-list>

        <div #loanProducts>
          <mat-list-item *mifosxHasPermission="'READ_LOANPRODUCT'">
            <mat-icon matListIcon [routerLink]="['loan-products']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['loan-products']">{{'labels.heading.Loan Products' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="!arrowBooleans[0]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="arrowBooleans[0]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[0]" [routerLink]="['loan-products']">{{'labels.text.Add new loan product or modify or inactivate loan product' | translate}}</p>
          </mat-list-item>
        </div>

        <div #savingsProducts>
          <mat-list-item *mifosxHasPermission="'READ_SAVINGSPRODUCT'">
            <mat-icon matListIcon [routerLink]="['saving-products']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['saving-products']">{{'labels.heading.Savings Products' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="!arrowBooleans[1]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="arrowBooleans[1]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[1]" [routerLink]="['saving-products']">{{'labels.text.Add new savings product or modify or inactivate savings product' | translate}}</p>
          </mat-list-item>
        </div>

        <div #shareProducts>
          <mat-list-item *mifosxHasPermission="'READ_SHAREPRODUCT'">
            <mat-icon matListIcon [routerLink]="['share-products']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['share-products']">{{'labels.heading.Share Products' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="!arrowBooleans[2]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="arrowBooleans[2]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[2]" [routerLink]="['share-products']">{{'labels.text.Add new share product or modify or inactivate share product' | translate}}</p>
          </mat-list-item>
        </div>

        <div #charges>
          <mat-list-item *mifosxHasPermission="'READ_CHARGE'">
            <mat-icon matListIcon [routerLink]="['charges']">
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['charges']">{{'labels.heading.Charges' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="!arrowBooleans[3]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="arrowBooleans[3]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[3]" [routerLink]="['charges']">{{'labels.text.Define charges/penalties for loan products, savings and deposit products' | translate}}</p>
          </mat-list-item>
        </div>

          <mat-list-item>
            <mat-icon matListIcon [routerLink]="['collaterals']">
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['collaterals']">{{'labels.heading.Collateral Management' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="!arrowBooleans[4]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="arrowBooleans[4]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[4]" [routerLink]="['collaterals']">{{'labels.text.Define collaterals for Collateral Management' | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_DELINQUENCY_BUCKET'">
            <mat-icon matListIcon [routerLink]="['delinquency-bucket-configurations']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['delinquency-bucket-configurations']">{{'labels.heading.Delinquency Buckets' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="!arrowBooleans[5]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="arrowBooleans[5]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[5]" [routerLink]="['delinquency-bucket-configurations']">{{'labels.text.Define delinquency day ranges and bucket set for loan products' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'READ_PRODUCTMIX'">
            <mat-icon matListIcon [routerLink]="['products-mix']">
              <fa-icon icon="random" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['products-mix']">{{'labels.heading.Products Mix' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="!arrowBooleans[6]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="arrowBooleans[6]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[6]" [routerLink]="['products-mix']">{{'labels.text.Defines rules for taking multiple rules' | translate}}</p>
          </mat-list-item>

        <div #fixedDepositProducts>
          <mat-list-item *mifosxHasPermission="'READ_FIXEDDEPOSITPRODUCT'">
            <mat-icon matListIcon [routerLink]="['fixed-deposit-products']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['fixed-deposit-products']">{{'labels.heading.Fixed Deposit Products' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="!arrowBooleans[7]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="arrowBooleans[7]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[7]" [routerLink]="['fixed-deposit-products']">{{'labels.text.Add, modify or inactivate a Fixed deposit product' | translate}}</p>
          </mat-list-item>
        </div>

        <div #recurringDepositProducts>
          <mat-list-item *mifosxHasPermission="'READ_RECURRINGDEPOSITPRODUCT'">
            <mat-icon matListIcon [routerLink]="['recurring-deposit-products']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['recurring-deposit-products']">{{'labels.heading.Recurring Deposit Products' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="!arrowBooleans[8]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="arrowBooleans[8]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[8]" [routerLink]="['recurring-deposit-products']">{{'labels.text.Add, modify or inactivate a Recurring Deposit product' | translate}}</p>
          </mat-list-item>
        </div>

          <mat-list-item *mifosxHasPermission="'READ_TAXGROUP'">
            <mat-icon matListIcon [routerLink]="['tax-configurations']">
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['tax-configurations']">{{'labels.heading.Manage Tax Configurations' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="!arrowBooleans[9]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="arrowBooleans[9]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[9]" [routerLink]="['tax-configurations']">{{'labels.text.Define Tax components and Tax groups' | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_FLOATINGRATE'">
            <mat-icon matListIcon [routerLink]="['floating-rates']">
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['floating-rates']">{{'labels.heading.Floating Rates' | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="!arrowBooleans[10]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="arrowBooleans[10]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[10]" [routerLink]="['floating-rates']">{{'labels.text.Define floating rates for loan products' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateCharges let-popover="popover">
  <h2>{{'labels.heading.Charges' | translate}}</h2>
  <p class="mw400">{{'labels.text.Two types of charges—fees and penalties' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCharges()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCharges()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateLoanProducts let-popover="popover">
  <h2>{{'labels.heading.Loan Products' | translate}}</h2>
  <p class="mw400">{{'labels.text.Loan products define the rules, default settings' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepLoanProducts()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepLoanProducts()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateSavingsProducts let-popover="popover">
  <h2>{{'labels.heading.Savings Products' | translate}}</h2>
  <p class="mw400">{{'labels.text.Savings products define the rules, default settings' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepSavingsProducts()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepSavingsProducts()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateShareProducts let-popover="popover">
  <h2>{{'labels.heading.Share Products' | translate}}</h2>
  <p class="mw400">{{'labels.text.Share products define the rules, default settings' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepShareProducts()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepShareProducts()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateFixedDepositProducts let-popover="popover">
  <h2>{{'labels.heading.Fixed Deposit Products' | translate}}</h2>
  <p class="mw400">{{'labels.text.Fixed Deposit Products defines the rules, default settings' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepFixedDepositProducts()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepFixedDepositProducts()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateRecurringDepositProducts let-popover="popover">
  <h2>{{'labels.heading.Recurring Deposit Products' | translate}}</h2>
  <p class="mw400">{{'labels.text.Recurring Deposit products define the rules, default settings' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepRecurringDepositProducts()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepRecurringDepositProducts()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>
