<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['../edit']">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column">

        <h2 class="mat-h2" fxFlexFill>{{ recurringDepositProduct.name }}</h2>

        <mat-divider [inset]="true"></mat-divider>

        <h3 class="mat-h3" fxFlexFill>{{'labels.inputs.Details' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Short Name' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.shortName }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.description">
          <span fxFlex="40%">{{'labels.inputs.Description' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.description }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{'labels.inputs.Currency' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.heading.Currency' | translate}}:</span>
          <span fxFlex="60%">({{ recurringDepositProduct.currency.code }}) {{ recurringDepositProduct.currency.name }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Decimal Places' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.currency.decimalPlaces }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Currency in multiples of' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.currency.inMultiplesOf | formatNumber}}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{'labels.heading.Terms' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Deposit Amount' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.depositAmount + ' (Min: ' + (recurringDepositProduct.minDepositAmount ? recurringDepositProduct.minDepositAmount : recurringDepositProduct.depositAmount) + ', Max: ' + (recurringDepositProduct.maxDepositAmount ? recurringDepositProduct.maxDepositAmount : recurringDepositProduct.depositAmount) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Interest Compounding Period' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.interestCompoundingPeriodType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Interest Posting Period' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.interestPostingPeriodType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Interest Calculated using' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.interestCalculationType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Days in Year' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.interestCalculationDaysInYearType.value }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{'labels.heading.Settings' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill *ngIf="recurringDepositProduct.lockinPeriodFrequency">
          <span fxFlex="40%">{{'labels.inputs.Lock-in Period' | translate}}:</span>
          <span fxFlex="60%" *ngIf="recurringDepositProduct.lockinPeriodFrequency !== undefined">
            {{ recurringDepositProduct.lockinPeriodFrequency }}
            {{ recurringDepositProduct.lockinPeriodFrequencyType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.minDepositTerm">
          <span fxFlex="40%">{{'labels.inputs.Minimum Deposit Term' | translate}}:</span>
          <span fxFlex="60%"
            *ngIf="recurringDepositProduct.minDepositTerm !== undefined">{{ recurringDepositProduct.minDepositTerm }}
            &nbsp;
            {{ recurringDepositProduct.minDepositTermType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.maxDepositTerm">
          <span fxFlex="40%">{{'labels.inputs.Maximum Deposit Term' | translate}}:</span>
          <span fxFlex="60%"
            *ngIf="recurringDepositProduct.maxDepositTerm !== undefined">{{ recurringDepositProduct.maxDepositTerm }}
            &nbsp;
            {{ recurringDepositProduct.maxDepositTermType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.inMultipleOfDepositTerm">
          <span fxFlex="40%">{{'labels.inputs.Deposit Term In multiple of' | translate}}:</span>
          <span fxFlex="60%"
            *ngIf="recurringDepositProduct.inMultipleOfDepositTerm !== undefined">{{ recurringDepositProduct.inMultipleOfDepositTerm }}
            -
            {{ recurringDepositProduct.inMultipleOfDepositTermType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.isMandatoryDeposit !== undefined">
          <span fxFlex="40%">{{'labels.inputs.Recurring Deposit Type' | translate}}:</span>
          <span fxFlex="60%" *ngIf="recurringDepositProduct.isMandatoryDeposit"> {{'labels.text.Mandatory' | translate}} </span>
          <span fxFlex="60%" *ngIf="!recurringDepositProduct.isMandatoryDeposit"> {{'labels.text.Voluntary' | translate}} </span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.preClosurePenalInterest">
          <span fxFlex="40%">{{'labels.inputs.Apply penal interest (less)' | translate}}:</span>
          <span fxFlex="60%"><span class="m-r-5">{{recurringDepositProduct.preClosurePenalInterest}}</span>%<span class="m-r-5">On</span>
            {{recurringDepositProduct.preClosurePenalInterestOnType.value}}
          </span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.inputs.Withhold Tax is Applicable' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.withHoldTax | yesNo }}</span>
        </div>

        <div fxFlexFill *ngIf="recurringDepositProduct.withHoldTax">
          <span fxFlex="40%">{{'labels.inputs.Withhold Tax group' | translate}}:</span>
          <span fxFlex="60%">{{ recurringDepositProduct.taxGroup.name }}</span>
        </div>

        <div *ngIf="recurringDepositProduct.activeChart" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" >

          <h3 class="mat-h3" fxFlexFill>{{'labels.heading.Interest Rate Charts' | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <div fxFlexFill  fxLayout="row wrap" fxLayout.lt-md="column">

            <div fxFlexFill *ngIf="recurringDepositProduct.activeChart.name">
              <span fxFlex="40%">{{'labels.inputs.Name' | translate}}:</span>
              <span fxFlex="60%">{{ recurringDepositProduct.activeChart.name }}</span>
            </div>

            <div fxFlexFill>
              <span fxFlex="40%">{{'labels.inputs.Valid from Date' | translate}}:</span>
              <span fxFlex="60%">{{ recurringDepositProduct.activeChart.fromDate | dateFormat }}</span>
            </div>

            <div fxFlexFill *ngIf="recurringDepositProduct.activeChart.endDate">
              <span fxFlex="40%">{{'labels.inputs.End Date' | translate}}:</span>
              <span fxFlex="60%">{{ recurringDepositProduct.activeChart.endDate | dateFormat }}</span>
            </div>

            <div fxFlexFill *ngIf="recurringDepositProduct.activeChart.description">
              <span fxFlex="40%">{{'labels.inputs.Description' | translate}}:</span>
              <span fxFlex="60%">{{ recurringDepositProduct.activeChart.description }}</span>
            </div>

            <div fxFlexFill>
              <span fxFlex="40%">{{'labels.inputs.Primary Grouping by Amount' | translate}}:</span>
              <span fxFlex="60%">{{ recurringDepositProduct.activeChart.isPrimaryGroupingByAmount | yesNo }}</span>
            </div>

            <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="recurringDepositProduct.activeChart.chartSlabs" multiTemplateDataRows>

              <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Period' | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  <div *ngIf="chartSlab.fromPeriod !== undefined">
                    {{ chartSlab.fromPeriod }} - {{ chartSlab.toPeriod }} {{ chartSlab.periodType.value }}
                  </div>
                  <div *ngIf="chartSlab.fromPeriod == undefined">
                    &nbsp;
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="amountRange">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Amount Range' | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  <div *ngIf="chartSlab.amountRangeFrom !== undefined">
                    {{ chartSlab.amountRangeFrom }} - {{ chartSlab.amountRangeTo }}
                  </div>

                  <div *ngIf="chartSlab.amountRangeFrom == undefined">
                    &nbsp;
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="annualInterestRate">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Interest' | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  {{ chartSlab.annualInterestRate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Description' | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  {{ chartSlab.description }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Actions' | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex">
                  <button mat-button color="primary" (click)="expandChartSlabIndex = expandChartSlabIndex === chartSlabIndex ? null : chartSlabIndex">
                    <span *ngIf="expandChartSlabIndex !== chartSlabIndex">
                      <fa-icon icon="eye" class="m-r-10"></fa-icon>
                      {{'labels.buttons.View Incentives' | translate}}
                    </span>
                    <span *ngIf="expandChartSlabIndex === chartSlabIndex">
                      <fa-icon icon="eye-slash" class="m-r-10"></fa-icon>
                      {{'labels.buttons.Hide Incentives' | translate}}
                    </span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="incentives">
                <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex" [attr.colspan]="chartSlabsDisplayedColumns.length">
                  <div fxLayout="row wrap" fxFlexFill class="incentives" [@expandChartSlab]="chartSlabIndex === expandChartSlabIndex? 'expanded' : 'collapsed'">
                    <mat-card fxLayout="row wrap" fxFlexFill>

                      <h4 class="mat-h4" fxFlex="23%">
                        {{'labels.heading.Incentives' | translate}}
                      </h4>

                      <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="chartSlab.incentives">

                        <ng-container matColumnDef="entityType">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Entity Type' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.entityType | find:recurringDepositProductTemplate.chartTemplate.entityTypeOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="attributeName">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Attribute Name' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.attributeName | find:recurringDepositProductTemplate.chartTemplate.attributeNameOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="conditionType">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Condition Type' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.conditionType | find:recurringDepositProductTemplate.chartTemplate.conditionTypeOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="attributeValue">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Attribute Value' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive" [ngSwitch]="incentive.attributeName">
                            <span *ngSwitchCase="2">{{ incentive.attributeValue | find:recurringDepositProductTemplate.chartTemplate.genderOptions:'id':'name' }}</span>
                            <span *ngSwitchCase="3">{{ incentive.attributeValue }}</span>
                            <span *ngSwitchCase="4">{{ incentive.attributeValue | find:recurringDepositProductTemplate.chartTemplate.clientTypeOptions:'id':'name' }}</span>
                            <span *ngSwitchCase="5">{{ incentive.attributeValue | find:recurringDepositProductTemplate.chartTemplate.clientClassificationOptions:'id':'name' }}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="incentiveType">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Incentive Type' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.incentiveType | find:recurringDepositProductTemplate.chartTemplate.incentiveTypeOptions:'id':'value'}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Amount' | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.amount }}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="incentivesDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: incentivesDisplayedColumns;"></tr>

                      </table>

                    </mat-card>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chartSlabsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: chartSlabsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: chartSlabsIncentivesDisplayedColumns;" class="incentives-row"></tr>

            </table>

            <mat-divider [inset]="true"></mat-divider>

          </div>

        </div>

        <div *ngIf="recurringDepositProduct.charges.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{'labels.heading.Charges' | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="recurringDepositProduct.charges">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Name' | translate}} </th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Type' | translate}} </th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeCalculationType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Amount' | translate}} </th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.amount | formatNumber }}
              </td>
            </ng-container>

            <ng-container matColumnDef="collectedon">
              <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Collected On' | translate}} </th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeTimeType.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

          </table>

        </div>

        <h3 class="mat-h3" fxFlexFill>{{'labels.heading.Accounting' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <mifosx-view-savings-accounting-details class="container"
        [accountingRule]="recurringDepositProduct.accountingRule"
        [accountingMappings]="recurringDepositProduct.accountingMappings"
        >
        </mifosx-view-savings-accounting-details>

        <div fxFlexFill *ngIf="recurringDepositProduct.paymentChannelToFundSourceMappings?.length || recurringDepositProduct.feeToIncomeAccountMappings?.length || recurringDepositProduct.penaltyToIncomeAccountMappings?.length " fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{'labels.inputs.Advanced Accounting Rules' | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <div *ngIf="recurringDepositProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap"
            fxLayout.lt-md="column">
            <h4 class="mat-h4" fxFlexFill>{{'labels.inputs.Configure Fund Sources for Payment Channels' | translate}}</h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="recurringDepositProduct.paymentChannelToFundSourceMappings">

              <ng-container matColumnDef="paymentTypeId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Payment Type' | translate}} </th>
                <td mat-cell *matCellDef="let paymentFundSource">
                  {{ paymentFundSource.paymentType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="fundSourceAccountId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Fund Source' | translate}} </th>
                <td mat-cell *matCellDef="let paymentFundSource">
                  {{ paymentFundSource.fundSourceAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

            </table>
          </div>


          <div *ngIf="recurringDepositProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap"
            fxLayout.lt-md="column">


            <h4 class="mat-h4" fxFlexFill>{{'labels.inputs.Fees to Specific Income Accounts' | translate}}</h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="recurringDepositProduct.feeToIncomeAccountMappings">

              <ng-container matColumnDef="chargeId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Fees' | translate}} </th>
                <td mat-cell *matCellDef="let feesIncome">
                  {{ feesIncome.charge.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="incomeAccountId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Income Account' | translate}} </th>
                <td mat-cell *matCellDef="let feesIncome">
                  {{ feesIncome.incomeAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

            </table>

          </div>

          <div *ngIf="recurringDepositProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap"
            fxLayout.lt-md="column">

            <h4 class="mat-h4" fxFlexFill>{{'labels.inputs.Penalties to Specific Income Accounts' | translate}}</h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="recurringDepositProduct.penaltyToIncomeAccountMappings">

              <ng-container matColumnDef="chargeId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Penalty' | translate}} </th>
                <td mat-cell *matCellDef="let penaltyIncome">
                  {{ penaltyIncome.charge.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="incomeAccountId">
                <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Income Account' | translate}} </th>
                <td mat-cell *matCellDef="let penaltyIncome">
                  {{ penaltyIncome.incomeAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

            </table>

          </div>

        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
