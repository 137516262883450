<div class="container">

  <mat-card>

    <form [formGroup]="roleForm" (ngSubmit)="submit()">

      <mat-card-content>

          <div fxLayout="column">

            <mat-form-field>
              <mat-label>{{ 'labels.inputs.Role Name' | translate }}</mat-label>
              <input matInput disabled formControlName="name">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'labels.inputs.Role Description' | translate }}</mat-label>
              <input matInput required formControlName="description">
              <mat-error *ngIf="roleForm.controls.description.hasError('required')">
                {{ 'labels.inputs.Description' | translate }} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

          </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!roleForm.valid || roleForm.pristine">{{'labels.buttons.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
