<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{"labels.buttons.Edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteAccountingClosure()" *mifosxHasPermission="'DELETE_GLCLOSURE'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{"labels.buttons.Delete" | translate}}
  </button>
</div>

<div class="container" *ngIf="glAccountClosure">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
         {{"labels.inputs.Office" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.officeName }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Closure Date" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.closingDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Closed By" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.createdByUsername }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Updated By" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.lastUpdatedByUsername }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Updated On"|translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.lastUpdatedDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Closure Creation Date"|translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.createdDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.Comments" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.comments }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
