<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'">
            <mat-icon matListIcon [routerLink]="['journal-entries/frequent-postings']">
              <fa-icon icon="sync" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['journal-entries/frequent-postings']">{{"labels.heading.Frequent Postings" | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="!arrowBooleans[0]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="arrowBooleans[0]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[0]" [routerLink]="['journal-entries/frequent-postings']">{{"labels.text.These are predefined postings" | translate}}</p>
          </mat-list-item>

          <div #createJournalEntries>
            <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'">
              <mat-icon matListIcon [routerLink]="['journal-entries/create']">
                <fa-icon icon="plus" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['journal-entries/create']">{{"labels.heading.Create Journal Entries" | translate}}</h4>
              <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="!arrowBooleans[1]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="arrowBooleans[1]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[1]" [routerLink]="['journal-entries/create']">{{"labels.text.Manual journal entry transactions recorded in a journal" | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'">
            <mat-icon matListIcon [routerLink]="['journal-entries']">
              <fa-icon icon="search" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['journal-entries']">{{"labels.heading.Search Journal Entries" | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="!arrowBooleans[2]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="arrowBooleans[2]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[2]" [routerLink]="['journal-entries']">{{"labels.text.Advanced search option for journal entries" | translate}}</p>
          </mat-list-item>

          <div #accountsLinked>
            <mat-list-item *mifosxHasPermission="'READ_FINANCIALACTIVITYACCOUNT'">
              <mat-icon matListIcon [routerLink]="['financial-activity-mappings']">
                <fa-icon icon="link" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['financial-activity-mappings']">{{"labels.heading.Accounts Linked to Financial Activities" | translate}}</h4>
              <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="!arrowBooleans[3]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="arrowBooleans[3]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[3]" [routerLink]="['financial-activity-mappings']">{{"labels.text.List of Financial Activities and GL Account Mappings" | translate}}</p>
            </mat-list-item>
          </div>

          <div #migrateOpeningBalances>
            <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'" >
              <mat-icon matListIcon [routerLink]="['migrate-opening-balances']">
                <fa-icon icon="hand-holding-usd" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['migrate-opening-balances']">{{"labels.heading.Migrate Opening Balances (Office-wise)" | translate}}</h4>
              <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="!arrowBooleans[4]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="arrowBooleans[4]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[4]" [routerLink]="['migrate-opening-balances']">{{"labels.text.Set or update office-level opening balances for GL accounts" | translate}}</p>
            </mat-list-item>
          </div>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <div #chartofAccounts>
            <mat-list-item *mifosxHasPermission="'READ_GLACCOUNT'">
              <mat-icon matListIcon [routerLink]="['chart-of-accounts']">
                <fa-icon icon="sitemap" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['chart-of-accounts']">{{"labels.heading.Chart of Accounts" | translate}}</h4>
              <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="!arrowBooleans[5]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="arrowBooleans[5]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[5]" [routerLink]="['chart-of-accounts']">{{"labels.text.List of accounts used by the organization" | translate}}</p>
            </mat-list-item>
          </div>

          <div #closingEntries>
            <mat-list-item *mifosxHasPermission="'READ_GLCLOSURE'" >
              <mat-icon matListIcon [routerLink]="['closing-entries']">
                <fa-icon icon="archive" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['closing-entries']">{{"labels.heading.Closing Entries" | translate}}</h4>
              <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="!arrowBooleans[6]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="arrowBooleans[6]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[6]" [routerLink]="['closing-entries']">{{"labels.text.Journal entries made at the end of accounting period" | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_ACCOUNTINGRULE'">
            <mat-icon matListIcon [routerLink]="['accounting-rules']">
              <fa-icon icon="list" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['accounting-rules']">{{"labels.heading.Accounting Rules" | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="!arrowBooleans[7]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="arrowBooleans[7]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[7]" [routerLink]="['accounting-rules']">{{"labels.text.List of all accounting rules" | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'EXECUTE_PERIODICACCRUALACCOUNTING'">
            <mat-icon matListIcon [routerLink]="['periodic-accruals']">
              <fa-icon icon="calendar" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['periodic-accruals']">{{"labels.heading.Accruals" | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="!arrowBooleans[8]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="arrowBooleans[8]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[8]" [routerLink]="['periodic-accruals']">{{"labels.text.Accrues income, expenses and liabilities" | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'VIEW_PROVISIONING_ENTRIES'">
            <mat-icon matListIcon [routerLink]="['provisioning-entries']">
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['provisioning-entries']">{{"labels.heading.Provisioning Entries" | translate}}</h4>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="!arrowBooleans[9]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="arrowBooleans[9]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[9]" [routerLink]="['provisioning-entries']">{{"labels.text.Create provisioning entries" | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateChartofAccounts let-popover="popover">
  <h2>{{"labels.heading.Chart of Accounts" | translate}}</h2>
  <p class="mw300">{{"labels.text.The Chart of Accounts - General Ledger Setup" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepChartofAccounts()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepChartofAccounts()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateAccountsLinked let-popover="popover">
  <h2>{{"labels.heading.Accounts Linked to Financial Activities" | translate}}</h2>
  <p class="mw400">{{"labels.text.Accounts linked to Financial Activities" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepAccountsLinked()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepAccountsLinked()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateMigrateOpeningBalances let-popover="popover">
  <h2>{{"labels.heading.Migrate Opening Balances (Office-wise)" | translate}}</h2>
  <p class="mw400">{{"labels.text.GLAccount Balances Application" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepMigrateOpeningBalances()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepMigrateOpeningBalances()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateClosingEntries let-popover="popover">
  <h2>{{"labels.heading.Closing Entries" | translate}}</h2>
  <p class="mw300">{{"labels.text.Accounting to journal entries" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepClosingEntries()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepClosingEntries()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateCreateJournalEntries let-popover="popover">
  <h2>{{"labels.heading.Create Journal Entries" | translate}}</h2>
  <p class="mw300">{{"labels.text.Journal Entries Transactions" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCreateJournalEntries()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCreateJournalEntries()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>
