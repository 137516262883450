<div class="container">

  <mat-card>

    <form [formGroup]="dataTableForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="40%">
            <mat-label>{{'labels.inputs.Data Table Name' | translate}}</mat-label>
            <input matInput required formControlName="datatableName">
          </mat-form-field>

          <mat-form-field fxFlex="20%">
            <mat-label>{{'labels.inputs.Application Table Name' | translate}}</mat-label>
            <mat-select required formControlName="apptableName">
              <mat-option *ngFor="let appTable of appTableData" [value]="appTable.value">
                {{ appTable.displayValue | translateKey:'inputs'}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="dataTableForm.controls.apptableName.hasError('required')">
              {{'labels.inputs.Application Table Name' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="20%" *ngIf="showEntitySubType">
            <mat-label>{{'labels.inputs.Entity SubType' | translate}}</mat-label>
            <mat-select formControlName="entitySubType">
              <mat-option *ngFor="let entitySubType of entitySubTypeData" [value]="entitySubType.value">
                {{ entitySubType.displayValue | translateKey:'inputs'}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="60%" fxLayout.lt-md="column">

          <p fxFlex="20%" class="mat-title">{{'labels.inputs.Columns' | translate}}</p>

          <button mat-raised-button fxFlex="20%" type="button" color="primary" (click)="addColumn()">
            <fa-icon icon="plus" class="m-r-10"></fa-icon>
            {{'labels.buttons.Add' | translate}} {{'labels.inputs.Columns' | translate}}
          </button>

        </div>

        <br>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{ 'labels.inputs.Name' | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnName }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Type' | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnDisplayType }} </td>
          </ng-container>

          <ng-container matColumnDef="length">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Length' | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnLength }} </td>
          </ng-container>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{ 'labels.inputs.Code' | translate }} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnCode }} </td>
          </ng-container>

          <ng-container matColumnDef="mandatory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.text.Mandatory' | translate}} </th>
            <td mat-cell *matCellDef="let column">
              <fa-icon *ngIf="!column.isColumnNullable" icon="check-circle" size="lg" class="column-mandatory"
                matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="column.isColumnNullable" icon="times-circle" size="lg" class="not-column-mandatory"
                matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="unique">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Unique' | translate}} </th>
            <td mat-cell *matCellDef="let column">
              <fa-icon *ngIf="column.isColumnUnique" icon="check-circle" size="lg" class="column-mandatory"
                matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!column.isColumnUnique" icon="times-circle" size="lg" class="not-column-mandatory"
                matTooltip="{{ 'tooltips.No' | translate }}" unique="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="indexed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Indexed' | translate}} </th>
            <td mat-cell *matCellDef="let column">
              <fa-icon *ngIf="column.isColumnIndexed" icon="check-circle" size="lg" class="column-mandatory"
                matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!column.isColumnIndexed" icon="times-circle" size="lg" class="not-column-mandatory"
                matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{ 'labels.inputs.Actions' | translate }} </th>
            <td mat-cell *matCellDef="let column" fxLayoutGap="15%" class="center">
              <button type="button" color="primary" mat-icon-button (click)="editColumn(column)" *ngIf="!column.system">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" color="warn" mat-icon-button (click)="deleteColumn(column)" *ngIf="!column.system">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!isFormEdited"
          *mifosxHasPermission="'UPDATE_DATATABLE'">{{'labels.buttons.Submit' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
