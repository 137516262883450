<div class="container">

  <mat-card>

    <form [formGroup]="accountNumberPreferenceForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{'labels.inputs.Account Type' | translate}}</mat-label>
            <mat-select required formControlName="accountType">
              <mat-option *ngFor="let accountType of accountNumberPreferencesTemplateData.accountTypeOptions" [value]="accountType.id">
                {{ accountType.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountNumberPreferenceForm.controls.accountType.hasError('required')">
              {{'labels.inputs.Account Type' | translate}} {{ "labels.commons.is" | translate}} <strong>{{ "labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.Prefix Field' | translate}}</mat-label>
            <mat-select formControlName="prefixType">
              <mat-option *ngFor="let prefixType of prefixTypeData" [value]="prefixType.id">
                {{ prefixType.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!accountNumberPreferenceForm.valid" *mifosxHasPermission="'CREATE_ACCOUNTNUMBERFORMAT'">{{'labels.buttons.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
