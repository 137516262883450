/** Angular Imports */
import { Component } from '@angular/core';

/**
 * Content component.
 */
@Component({
  selector: 'mifosx-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  constructor() { }

}
