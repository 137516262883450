<h1 mat-dialog-title>{{"labels.heading.Welcome to Mifos" | translate}}</h1>

<mat-dialog-content>

<h3 class="mw600">{{"labels.heading.Configure Mifos Organization Needs" | translate}}</h3>

<h3>{{"labels.heading.Configuration Wizard Setup" | translate}}</h3>

<h3>{{"labels.heading.Progress Bar" | translate}} : 100 %</h3>

<mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close>{{"labels.buttons.Close Config. Wizard" | translate}}</button>
</mat-dialog-actions>
