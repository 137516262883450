<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_DELINQUENCY_BUCKET'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="deleteDelinquencyBucket()" *mifosxHasPermission="'DELETE_DELINQUENCY_BUCKET'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{'labels.buttons.Delete' | translate }}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.Name" | translate}}
        </div>

        <div fxFlex="50%">
          {{ delinquencyBucketData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.Classification" | translate}} {{"labels.inputs.Name" | translate}}
        </div>

        <div fxFlex="25%" class="mat-body-strong">
          {{"labels.inputs.Days From" | translate}}
        </div>

        <div fxFlex="25%" class="mat-body-strong">
          {{"labels.inputs.Days Till" | translate}}
        </div>
      </div>


      <div fxLayout="row wrap" class="content" *ngFor="let range of delinquencyBucketData.ranges">

        <div fxFlex="50%">
          {{ range.classification }}
        </div>

        <div fxFlex="25%">
          {{ range.minimumAgeDays }}
        </div>

        <div fxFlex="25%">
          {{ range.maximumAgeDays }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
