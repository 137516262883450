<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
    <span *mifosxHasPermission="'ACTIVATE_HOLIDAY'">
    <button mat-raised-button color="accent" (click)="activateHoliday()" *ngIf="holidayData.status.value !== 'Active'">
      <fa-icon icon="lock-open" class="m-r-10"></fa-icon>
      {{'labels.buttons.Activate' | translate }}
    </button>
  </span>
    <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_HOLIDAY'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate }}
  </button>
    <button mat-raised-button color="warn" (click)="deleteHoliday()" *mifosxHasPermission="'DELETE_HOLIDAY'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{'labels.buttons.Delete' | translate }}
  </button>
</div>

<div class="container">

    <mat-card>

        <mat-card-content>

            <div fxLayout="row wrap" class="content">

                <div fxFlex="50%" class="header">
                    {{ 'labels.inputs.Name' | translate }}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.name }}
                </div>

                <div fxFlex="50%" class="header">
                    {{ 'labels.inputs.From Date' | translate }}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.fromDate | dateFormat }}
                </div>

                <div fxFlex="50%" class="header">
                    {{ 'labels.inputs.To Date' | translate }}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.toDate | dateFormat }}
                </div>

                <div fxFlex="50%" class="header">
                    {{ 'labels.inputs.Repayments Scheduled To' | translate }}
                </div>

                <div fxFlex="50%" *ngIf="holidayData.repaymentsRescheduledTo !== undefined && holidayData.repaymentsRescheduledTo !== null">
                    {{ holidayData.repaymentsRescheduledTo | dateFormat }}
                </div>

                <div fxFlex="50%" *ngIf="holidayData.repaymentsRescheduledTo === undefined || holidayData.repaymentsRescheduledTo === null">
                    {{ 'labels.inputs.Next Repayment Date' | translate }}
                </div>

            </div>

        </mat-card-content>

    </mat-card>

</div>
