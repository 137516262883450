<form [formGroup]="smsCampaignDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.Campaign Name' | translate }}</mat-label>
      <input matInput formControlName="campaignName" required>
      <mat-error>
        {{ 'labels.inputs.Campaign Name' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="!smsCampaignDetailsForm.controls.isNotification.value">
      <mat-label>{{ 'labels.inputs.SMS Provider' | translate }}</mat-label>
      <mat-select formControlName="providerId">
        <mat-option *ngFor="let provider of smsProviders" [value]="provider.id">
          {{ provider.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.Trigger Type' | translate }}</mat-label>
      <mat-select formControlName="triggerType" required>
        <mat-option *ngFor="let triggerType of triggerTypes" [value]="triggerType.id">
          {{ triggerType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ 'labels.inputs.Trigger Type' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="isNotification" fxFlex="48%">
      {{ 'labels.inputs.Is Notification' | translate }}?
    </mat-checkbox>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('recurrenceStartDate')" (click)="recurrenceStartDatePicker.open()">
      <mat-label>{{ 'labels.inputs.Schedule Date' | translate }}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="recurrenceStartDatePicker"
        formControlName="recurrenceStartDate" required>
      <mat-datepicker-toggle matSuffix [for]="recurrenceStartDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #recurrenceStartDatePicker></mat-datepicker>
      <mat-error>
        {{ 'labels.inputs.Schedule Date' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('frequency')">
      <mat-label>{{ 'labels.inputs.Repeats' | translate }}</mat-label>
      <mat-select formControlName="frequency" required>
        <mat-option [value]="1">{{ 'labels.inputs.Daily' | translate }}</mat-option>
        <mat-option [value]="2">{{ 'labels.inputs.Weekly' | translate }}</mat-option>
        <mat-option [value]="3">{{ 'labels.inputs.Monthly' | translate }}</mat-option>
        <mat-option [value]="4">{{ 'labels.inputs.Yearly' | translate }}</mat-option>
      </mat-select>
      <mat-error>
        {{ 'labels.inputs.Repetition Frequency' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('interval')">
      <mat-label>{{ 'labels.inputs.Repetition Interval' | translate }}</mat-label>
      <mat-select formControlName="interval" required>
        <mat-option *ngFor="let interval of repetitionIntervals" [value]="interval">
          {{ interval }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ 'labels.inputs.Repetition Interval' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('repeatsOnDay')">
      <mat-label>{{ 'labels.inputs.Repeats on Day' | translate }}</mat-label>
      <mat-select formControlName="repeatsOnDay" required>
        <mat-option value="1">{{ 'labels.inputs.Monday' | translate }}</mat-option>
        <mat-option value="2">{{ 'labels.inputs.Tuesday' | translate }}</mat-option>
        <mat-option value="3">{{ 'labels.inputs.Wednesday' | translate }}</mat-option>
        <mat-option value="4">{{ 'labels.inputs.Thursday' | translate }}</mat-option>
        <mat-option value="5">{{ 'labels.inputs.Friday' | translate }}</mat-option>
        <mat-option value="6">{{ 'labels.inputs.Saturday' | translate }}</mat-option>
        <mat-option value="7">{{ 'labels.inputs.Sunday' | translate }}</mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.At least" |translate}} <strong>{{"labels.inputs.one" |translate}}</strong> {{"labels.inputs.day must be selected" |translate}}

      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.Business Rule' | translate }}</mat-label>
      <mat-select formControlName="runReportId" required>
        <mat-option *ngFor="let rule of businessRules" [value]="rule.reportId">
          {{ rule.reportName }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ 'labels.inputs.Business Rule' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%"
    *ngIf="!smsCampaignDetailsForm.controls.runReportId.value">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{ 'labels.buttons.Previous' | translate }}
    </button>
    <button mat-raised-button matStepperNext>
      {{ 'labels.buttons.Next' | translate }}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

  <mifosx-business-rule-parameters
    *ngIf="smsCampaignDetailsForm.controls.runReportId.value"
    [paramData]="paramData"
    (templateParameters)="passParameters($event)"
  >
  </mifosx-business-rule-parameters>

</form>
