<div *ngIf="isJournalEntryLoaded">

  <div fxLayout="row" fxLayoutAlign="end" class="container m-b-20" *ngIf="dataSource?.data[0].manualEntry">
    <button mat-raised-button color="primary" (click)="revertTransaction()"
      *mifosxHasPermission="'REVERSE_JOURNALENTRY'" [disabled]="dataSource?.data[0].reversed">
      <span *ngIf="!dataSource.data[0].reversed">
        <fa-icon icon="undo" class="m-r-10"></fa-icon>{{ 'labels.buttons.Revert Transaction' | translate }}
      </span>
      <span *ngIf="dataSource.data[0].reversed">{{ 'labels.buttons.Transaction Reverted' | translate }}</span>
    </button>
  </div>

  <div class="container m-b-20" *ngIf="isViewTransaction()">

    <mat-card class="mat-elevation-z8" *ngIf="dataSource">

      <mat-card-content>

        <div fxLayout="row wrap" class="content">

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            {{ 'labels.inputs.Office' | translate }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].officeName }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            {{ 'labels.inputs.Transaction Date' | translate }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].transactionDate | dateFormat }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            {{ 'labels.inputs.Created By' | translate }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].createdByUserName }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            {{ 'labels.inputs.Submitted on' | translate }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].submittedOnDate | datetimeFormat }}
          </div>

        </div>

      </mat-card-content>

    </mat-card>

  </div>

  <div class="mat-elevation-z8 container" fxLayoutAlign="center" fxLayout="row wrap">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Entry ID' | translate }}</th>
        <td mat-cell *matCellDef="let transaction"> {{ transaction.id }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountType">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Type' | translate }}</th>
        <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountType.value }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountCode">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Account Code' |
          translate }} </th>
        <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountCode }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountName">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Account Name' |
          translate }} </th>
        <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountName }} </td>
      </ng-container>

      <ng-container matColumnDef="debit">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Debit' | translate }}</th>
        <td mat-cell *matCellDef="let transaction"><span *ngIf="transaction.entryType.value === 'DEBIT'"> {{
            (transaction.currency.displaySymbol || transaction.currency.code) }} {{ transaction.amount | formatNumber }}
          </span></td>
      </ng-container>

      <ng-container matColumnDef="credit">
        <th mat-header-cell class="center" *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Credit' | translate }}</th>
        <td mat-cell *matCellDef="let transaction"><span *ngIf="transaction.entryType.value === 'CREDIT'"> {{
            (transaction.currency.displaySymbol || transaction.currency.code) }} {{ transaction.amount | formatNumber }}
          </span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewJournalEntry(row)" class="select-row"></tr>

    </table>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" class="m-t-15 m-b-15">
      <button type="button" color="primary" mat-raised-button (click)="goBack()">{{ 'labels.buttons.Back' | translate
        }}</button>
    </div>

  </div>
</div>

<div class="container m-b-20" *ngIf="!isJournalEntryLoaded">
  <div class="alert">
    <div class="message">
      <i class="fa fa-exclamation-circle alert-check"></i>
      {{"labels.text.No data found" | translate}}
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" class="m-t-15 m-b-15">
    <button type="button" color="primary" mat-raised-button (click)="goBack()">{{ 'labels.buttons.Back' | translate
      }}</button>
  </div>
</div>
