<div class="container">

  <mat-card>

    <form [formGroup]="chargeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Charge Applies To" | translate}}</mat-label>
            <mat-select required formControlName="chargeAppliesTo">
              <mat-option *ngFor="let chargeAppliesTo of chargeData.chargeAppliesToOptions"
                [value]="chargeAppliesTo.id">
                {{ chargeAppliesTo.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" style="margin-top:10px;">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Name" | translate}}</mat-label>
            <input matInput required autofocus formControlName="name">
            <mat-error *ngIf="chargeForm.controls.name.hasError('required')">
              {{"labels.inputs.Name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Currency" | translate}}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of chargeData.currencyOptions" [value]="currency.code">
                {{ currency.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="chargeForm.controls.currencyCode.hasError('required')">
              {{"labels.inputs.Currency" | translate}} {{"labels.inputs.Type" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>

          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Charge Time Type" | translate}}</mat-label>
            <mat-select required formControlName="chargeTimeType">
              <mat-option *ngFor="let chargeTime of chargeTimeTypeOptions" [value]="chargeTime.id">
                {{ chargeTime.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="chargeForm.controls.chargeTimeType.hasError('required')">
              {{"labels.inputs.Charge Time Type" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>

          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Charge Calculation" | translate}}</mat-label>
            <mat-select required formControlName="chargeCalculationType">
              <mat-option *ngFor="let chargeCalculation of chargeCalculationTypeOptions" [value]="chargeCalculation.id">
                {{ chargeCalculation.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="chargeForm.controls.chargeCalculationType.hasError('required')">
              {{"labels.inputs.Charge Calculation Type" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="chargePaymentMode">
            <mat-label>{{"labels.inputs.Charge Payment By" | translate}}</mat-label>
            <mat-select required formControlName="chargePaymentMode">
              <mat-option *ngFor="let chargePaymentMode of chargeData.chargePaymetModeOptions"
                [value]="chargePaymentMode.id">
                {{ chargePaymentMode.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="chargeForm.controls.chargePaymentMode.hasError('required')">
              {{"labels.inputs.Charge Payment Mode" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Amount" | translate}}</mat-label>
            <input matInput required autofocus type="text" formControlName="amount">
            <mat-error *ngIf="chargeForm.controls.amount.hasError('required')">
              {{"labels.inputs.Amount" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>
          <!--
   * minCap and maxCap only allowed for loan ,shares and savings entitites
   * In Loan case: Only for the "charge calculation type" set as "%amount", "% loan amount+interest", "%interest",and "%disbursement amount".
   * In saving case: Only for 1. charge time type is "withdrawlfee" or "savings no activity fee" with charge calculation type as "% amount"
   * In shares case: Only for charge time type: SHARE_PURCHASE and SHARE_REDEEM and with charge calculation type as % amount only
  -->
          <mat-form-field fxFlex="48%"
            *ngIf="(chargeForm.controls.chargeAppliesTo.value === 1 && (chargeForm.controls.chargeCalculationType.value === 2 || chargeForm.controls.chargeCalculationType.value === 3 || chargeForm.controls.chargeCalculationType.value === 4 || chargeForm.controls.chargeCalculationType.value==5)) || (chargeForm.controls.chargeAppliesTo.value === 2 && (chargeForm.controls.chargeTimeType.value === 16 || chargeForm.controls.chargeTimeType.value === 5)&&(chargeForm.controls.chargeCalculationType.value === 2)) || (chargeForm.controls.chargeAppliesTo.value === 4 && (chargeForm.controls.chargeTimeType.value==14 || chargeForm.controls.chargeTimeType.value==15) && chargeForm.controls.chargeCalculationType.value === 2)">
            <mat-label>{{"labels.inputs.Minimum Charge Cap" | translate}}</mat-label>
            <input matInput autofocus formControlName="minCap">
          </mat-form-field>
          <mat-form-field fxFlex="48%"
            *ngIf="(chargeForm.controls.chargeAppliesTo.value === 1 && (chargeForm.controls.chargeCalculationType.value === 2 || chargeForm.controls.chargeCalculationType.value === 3 || chargeForm.controls.chargeCalculationType.value === 4 || chargeForm.controls.chargeCalculationType.value==5)) || (chargeForm.controls.chargeAppliesTo.value === 2 && (chargeForm.controls.chargeTimeType.value === 16 || chargeForm.controls.chargeTimeType.value === 5)&&(chargeForm.controls.chargeCalculationType.value === 2)) || (chargeForm.controls.chargeAppliesTo.value === 4 && (chargeForm.controls.chargeTimeType.value==14 || chargeForm.controls.chargeTimeType.value==15) && chargeForm.controls.chargeCalculationType.value === 2)">
            <mat-label>{{"labels.inputs.Maximum Charge Cap" | translate}}</mat-label>
            <input matInput autofocus formControlName="maxCap">
          </mat-form-field>

          <mifosx-gl-account-selector fxFlex="48%" *ngIf="showGLAccount"
            [inputFormControl]="chargeForm.controls.incomeAccountId"
            [glAccountList]="chargeData.incomeOrLiabilityAccountOptions.incomeAccountOptions" [required]="true" [inputLabel]="'Income from Charge'">
          </mifosx-gl-account-selector>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Tax Group" | translate}}</mat-label>
            <mat-select *ngIf="chargeData.taxGroup" formControlName="taxGroupId">
              <mat-option *ngFor="let taxGroup of chargeData.taxGroupOptions" [value]="taxGroup.id">
                {{ taxGroup.name }}
              </mat-option>
            </mat-select>

            <mat-select *ngIf="!chargeData.taxGroup" formControlName="taxGroupId">
              <mat-option *ngFor="let taxGroup of chargeData.taxGroupOptions" [value]="taxGroup.id">
                {{ taxGroup.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="48%" class="password-never-expires-wrapper checkbox" *ngIf="addFeeFrequency">
            <mat-checkbox labelPosition="before" [checked]="addFeeFrequency && showFeeOptions"
              (change)="getFeeFrequency($event.checked)">
              {{"labels.inputs.Add Fee Frequency" | translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="48%" *ngIf="addFeeFrequency && showFeeOptions">
            <mat-label>{{"labels.inputs.Frequency Interval" | translate}}</mat-label>
            <input matInput required autofocus type="text" formControlName="feeInterval">
            <mat-error *ngIf="chargeForm.controls.feeInterval.hasError('required')">
              {{"labels.inputs.Frequency Interval" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="addFeeFrequency && showFeeOptions">
            <mat-label>{{"labels.inputs.Charge Frequency" | translate}}</mat-label>
            <mat-select required formControlName="feeFrequency">
              <mat-option *ngFor="let chargeFrequency of chargeData.feeFrequencyOptions" [value]="chargeFrequency.id">
                {{ chargeFrequency.value | translateKey:'catalogs' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="chargeForm.controls.feeFrequency.hasError('required')">
              {{"labels.inputs.Add Fee Frequency" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" style="margin-top:10px;">
          <div fxFlex="48%" class="password-never-expires-wrapper">
            <mat-checkbox labelPosition="before" formControlName="active">
              {{"labels.status.Active" | translate}}
            </mat-checkbox>
          </div>

          <div fxFlex="48%" class="send-password-to-email-wrapper" *ngIf="showPenalty">
            <mat-checkbox labelPosition="before" formControlName="penalty">
              {{"labels.commons.Is" | translate}} {{"labels.inputs.Penalty" | translate}}
            </mat-checkbox>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.Cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!chargeForm.valid"
          *mifosxHasPermission="'UPDATE_CHARGE'">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
