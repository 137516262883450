<div>
  <h2>{{'labels.inputs.Job' | translate}}: {{ displayName }}</h2>
  <table mat-table [dataSource]="updatedJobParameters">
    <ng-container matColumnDef="parameterName">
      <th mat-header-cell *matHeaderCellDef>{{'labels.inputs.Parameter' | translate}} {{'labels.inputs.Name' | translate}}</th>
      <td mat-cell *matCellDef="let element; index as i">
        <mat-form-field>
          <input matInput type="text" [placeholder]="('labels.inputs.Parameter' | translate) + ' ' + ('labels.inputs.Name' | translate)" [(ngModel)]="element.parameterName" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="parameterValue">
      <th mat-header-cell *matHeaderCellDef>{{'labels.inputs.Parameter' | translate}} {{'labels.inputs.Value' | translate}}</th>
      <td mat-cell *matCellDef="let element; index as i">
        <mat-form-field>
          <input matInput type="text" [placeholder]="('labels.inputs.Parameter' | translate) + ' ' + ('labels.inputs.Value' | translate)"  [(ngModel)]="element.parameterValue" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let rowIndex = index">
        <button type="button" mat-icon-button color="warn"
          (click)="deleteParameter(rowIndex)" [matTooltip]="('labels.buttons.Delete' | translate)"
          matTooltipPosition="left">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="first-row"></tr>
    <tr mat-row *matRowDef="let updatedJobParameters; columns: columnsToDisplay"></tr>
  </table>

  <button mat-button (click)="addParameter()">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    {{'labels.buttons.Add' | translate}} {{'labels.inputs.Parameter' | translate}}
  </button>
</div>
