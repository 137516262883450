<div class="container">

  <mat-card>

    <form [formGroup]="hookForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="4%">

          <mat-form-field fxFlex="40%">
            <mat-label>{{"labels.inputs.Hook Template" | translate}}</mat-label>
            <mat-select required formControlName="name">
              <mat-option *ngFor="let hookTemplate of hooksTemplateData.templates" [value]="hookTemplate.name">
                {{ hookTemplate.name | translateKey:'text'}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hookForm.controls.name.hasError('required')">
              {{"labels.inputs.Hook Template" | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="40%">
            <mat-label>{{"labels.inputs.Display Name" | translate}}</mat-label>
            <input matInput required formControlName="displayName">
            <mat-error *ngIf="hookForm.controls.displayName.hasError('required')">
              {{"labels.inputs.Display Name" | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div class="is-active-wrapper">
            <mat-checkbox class="is-active" fxFlex="10%" labelPosition="before" formControlName="isActive">
              {{'labels.inputs.Is Active' | translate}}?
            </mat-checkbox>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="4%">

          <mat-form-field fxFlex="48%" *ngIf="hookForm.controls.name.value === 'Web'">
            <mat-label>{{'labels.inputs.Content Type' | translate}}</mat-label>
            <mat-select required formControlName="contentType">
              <mat-option [value]="'json'">
                {{'labels.inputs.json' | translate}}
              </mat-option>
              <mat-option [value]="'form'">
                {{'labels.inputs.form' | translate}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hookForm.controls.contentType.hasError('required')">
              {{'labels.inputs.Content Type' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="hookForm.controls.name.value === 'SMS Bridge'">
            <mat-label>{{'labels.inputs.Phone Number' | translate}}</mat-label>
            <input matInput type="tel" #input maxlength="10" required formControlName="phoneNumber">
            <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
            <mat-error *ngIf="hookForm.controls.phoneNumber.hasError('required')">
              {{'labels.inputs.Payload URL' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Payload URL' | translate}}</mat-label>
            <input matInput required formControlName="payloadUrl">
            <mat-error *ngIf="hookForm.controls.payloadUrl.hasError('required')">
              {{'labels.inputs.Payload URL' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="4%"
          *ngIf="hookForm.controls.name.value === 'SMS Bridge'">

          <mat-form-field fxFlex="30%">
            <mat-label>{{'labels.inputs.SMS Provider' | translate}}</mat-label>
            <input matInput required formControlName="smsProvider">
            <mat-error *ngIf="hookForm.controls.smsProvider.hasError('required')">
              {{'labels.inputs.SMS Provider' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="30%">
            <mat-label>{{'labels.inputs.SMS Provider Account ID' | translate}}</mat-label>
            <input matInput required formControlName="smsProviderAccountId">
            <mat-error *ngIf="hookForm.controls.smsProviderAccountId.hasError('required')">
              {{'labels.inputs.SMS Provider Account ID' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="30%">
            <mat-label>{{'labels.inputs.SMS Provider Token' | translate}}</mat-label>
            <input matInput required formControlName="smsProviderToken">
            <mat-error *ngIf="hookForm.controls.smsProviderToken.hasError('required')">
              {{'labels.inputs.SMS Provider Token' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        <br>

        <mat-divider [inset]="true"></mat-divider>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="60%" fxLayout.lt-md="column">

          <p fxFlex="20%" class="mat-title">{{'labels.inputs.Events' | translate}}</p>

          <button mat-raised-button fxFlex="20%" type="button" color="primary" (click)="addEvent()">
            <fa-icon icon="plus" class="m-r-10"></fa-icon>
            {{'labels.buttons.Add' | translate}} {{'labels.inputs.Events' | translate}}
          </button>

        </div>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="entityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Entity Name' | translate}} </th>
            <td mat-cell *matCellDef="let event"> {{ event.entityName }} </td>
          </ng-container>

          <ng-container matColumnDef="actionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Action' | translate }} {{ 'labels.inputs.Name' | translate}} </th>
            <td mat-cell *matCellDef="let event"> {{ event.actionName }} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.Actions' | translate }} </th>
            <td mat-cell *matCellDef="let i = index">
              <button type="button" color="warn" mat-icon-button
                (click)="deleteEvent(i)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.inputs.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="(!hookForm.valid || hookForm.pristine) && !eventsDataChanged" *mifosxHasPermission="'UPDATE_HOOK'">{{ 'labels.inputs.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
