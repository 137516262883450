<div class="tab-container mat-typography">

  <div fxLayout="row" fxLayoutAlign="start">
    <div class="m-b-10">
      <h3>{{datatableName}}</h3>
    </div>
    <div class="action-button m-b-7" fxLayoutGap="10px">
      <span *mifosxHasPermission="'CREATE_' + datatableName">
        <button mat-raised-button color="primary" (click)="add()">
          <fa-icon icon="plus" class="m-r-10"></fa-icon>{{ 'labels.buttons.Add' | translate }}
        </button>
      </span>
      <span *mifosxHasPermission="'DELETE_' + datatableName" [fxShow]="isSelected">
        <button class="delete-button" mat-raised-button color="warn" (click)="deleteSelected()">
          <fa-icon icon="trash" class="m-r-10"></fa-icon>{{ 'labels.buttons.Delete Selected' | translate }}
        </button>
      </span>
      <span *mifosxHasPermission="'DELETE_' + datatableName" [fxShow]="datatableData.length > 0">
        <button class="delete-button" mat-raised-button color="warn" (click)="delete()">
          <fa-icon icon="trash" class="m-r-10"></fa-icon>{{ 'labels.buttons.Delete All' | translate }}
        </button>
      </span>
    </div>
  </div>

  <div class="m-t-10">
    <table #dataTable mat-table [dataSource]="datatableData" class="mat-elevation-z1 m-b-25" [fxHide]="isLoading">

      <ng-container *ngFor="let datatableColumn of datatableColumns;let i = index" [matColumnDef]="datatableColumn">
        <ng-container *ngIf="i === 0">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let data">
            <mat-checkbox class="center" (click)="$event.stopPropagation()"
              (change)="$event ? itemToggle(data) : null" [checked]="selection.isSelected(data)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="i > 0">
          <th mat-header-cell class="right" *matHeaderCellDef> {{ getInputName(datatableColumn) }} </th>
          <td mat-cell class="right" *matCellDef="let data" [ngClass]="isToDelete(data)">
              {{ formatValue(data, datatableColumn) }}
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="datatableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: datatableColumns;"></tr>

    </table>
  </div>

</div>
