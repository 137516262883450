<div class="container">

  <mat-card>

    <form #accountFormRef [formGroup]="glAccountForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Account Type" | translate}}</mat-label>
            <mat-select required formControlName="type">
              <mat-option *ngFor="let accountType of accountTypeData" [value]="accountType.id">
                {{ 'labels.inputs.accounting.' + accountType.value | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.type.hasError('required')">
              {{"labels.inputs.Account Type" | translate}} {{"labels.commons.is" | translate}}
              <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Account Name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="glAccountForm.controls.name.hasError('required')">
              {{"labels.inputs.Account Name" | translate}} {{"labels.commons.is" | translate}}
              <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Account Usage" | translate}}</mat-label>
            <mat-select required formControlName="usage">
              <mat-option *ngFor="let accountUsage of accountUsageData" [value]="accountUsage.id">
                {{ accountUsage.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.usage.hasError('required')">
              {{"labels.inputs.Account Usage" | translate}} {{"labels.commons.is" | translate}}
              <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.GL Code" | translate}}</mat-label>
            <input matInput required formControlName="glCode">
            <mat-error *ngIf="glAccountForm.controls.glCode.hasError('required')">
              {{"labels.inputs.GL Code" | translate}} {{"labels.commons.is" | translate}}
              <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mifosx-gl-account-selector fxFlex="48%"
            [inputFormControl]="glAccountForm.controls.parentId"
            [glAccountList]="parentData"
            [required]="false"
            [inputLabel]="'Parent'"
          >
          </mifosx-gl-account-selector>

          <mat-form-field fxFlex="28%">
            <mat-label>{{"labels.inputs.Tag" | translate}}</mat-label>
            <mat-select formControlName="tagId">
              <mat-option *ngFor="let tag of tagData" [value]="tag.id">
                {{ tag.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="18%" class="manual-entries-allowed-wrapper">
            <mat-checkbox labelPosition="before" formControlName="manualEntriesAllowed" class="manual-entries-allowed">
              {{"labels.inputs.Manual Entries Allowed" | translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="98%">
            <mat-label>{{"labels.inputs.Description" | translate}}</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="[cancelRoute]">{{"labels.buttons.Cancel" |
          translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!glAccountForm.valid"
          *mifosxHasPermission="'CREATE_GLACCOUNT'">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateAccountFormRef let-popover="popover">
  <h2>{{"labels.heading.Create GL account" | translate}}</h2>
  <p class="mw400">{{"labels.text.Filling Details" | translate}} <a
      href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67141745/Chart+of+Accounts+-+General+Ledger+Setup"
      target="_blank">{{"labels.inputs.Chart of Accounts" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{"labels.buttons.Create GL Account" |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.Back" |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.Next" |
      translate}}</button>
  </div>
</ng-template>
