<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_STAFF'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate }}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.First Name' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.firstname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Last Name' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.lastname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Office' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Is Loan Officer' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.isLoanOfficer === true | yesNo }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="employeeData.mobileNo">
          {{'labels.inputs.Mobile Number for SMS' | translate }}
        </div>

        <div fxFlex="50%" *ngIf="employeeData.mobileNo">
          {{ employeeData.mobileNo }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Status' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.isActive === true ? 'Active' : 'Inactive' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Joining Date' | translate }}
        </div>

        <div fxFlex="50%">
          {{ employeeData.joiningDate | dateFormat }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
