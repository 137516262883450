<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button color="primary" (click)="editSchedule()">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{"labels.buttons.Edit Schedule" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <form [formGroup]="centerEditMeetingForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.Repetition Frequency" | translate}}</mat-label>
            <mat-select formControlName="frequency">
              <mat-option *ngFor="let option of frequencyOptions" [value]="option.id">
                {{ option.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="centerEditMeetingForm.controls.frequency.hasError('repeatsOnDay')">
              {{"labels.inputs.Repetition Frequency" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Repetition Interval" | translate}}</mat-label>
            <mat-select formControlName="interval">
              <mat-option *ngFor="let interval of repetitionIntervals" [value]="interval">
                {{ interval }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="centerEditMeetingForm.controls.interval.hasError('repeatsOnDay')">
              {{"labels.inputs.Repetition Interval" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="centerEditMeetingForm.contains('repeatsOnDay')">
          <mat-label>{{"labels.inputs.Repeats on Day" | translate}}</mat-label>
          <mat-select formControlName="repeatsOnDay" required>
            <mat-option *ngFor="let day of repeatsOnDays" [value]="day.id">
              {{ day.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="centerEditMeetingForm.controls.repeatsOnDay.hasError('repeatsOnDay')">
            {{"labels.inputs.At least" | translate}} <strong>{{"labels.inputs.one" | translate}}</strong> {{"labels.inputs.day must be selected" | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="m-b-5" (click)="startDatePicker.open()">
          <mat-label>{{"labels.inputs.Above Changes are Effective from" | translate}}</mat-label>
          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="centerEditMeetingForm.controls.startDate.hasError('required')">
            {{"labels.inputs.Changes Affection Date" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
          </mat-error>
          <mat-hint>
            {{"labels.text.Repeats' and 'Repeats every" | translate}}
          </mat-hint>
        </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!centerEditMeetingForm.valid" (click)="submit()">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
