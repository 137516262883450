<div class="container space-top" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

  <mat-form-field fxFlex="31%">
    <mat-label>{{'labels.inputs.Job Name' | translate}}</mat-label>
    <mat-select [formControl]="jobName" (selectionChange)="getWorkflowJobSteps($event.value)">
      <mat-option *ngFor="let job of jobNameOptions" [value]="job">
        {{ job | translateKey:'catalogs' }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="action-button" fxLayoutGap="25px" *ngIf="jobStepsData.length > 0">
    <button mat-raised-button color="primary" (click)="addJobStep()">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>{{ 'labels.buttons.Link Job Step' | translate}}
    </button>
  </div>

  <div class="mat-elevation-z8 container" *ngIf="jobStepsData.length > 0">

    <table mat-table [dataSource]="jobStepsData" #table cdkDropList [cdkDropListData]="jobStepsData"
      (cdkDropListDropped)="dropTable($event)">

      <ng-container matColumnDef="stepName">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.Step Name' | translate}} </th>
        <td mat-cell *matCellDef="let jobStep"> {{ jobStep.stepName }} </td>
      </ng-container>

      <ng-container matColumnDef="stepOrder">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.Order' | translate }} </th>
        <td mat-cell *matCellDef="let jobStep"> {{ jobStep.order }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{ "labels.inputs.Actions" | translate}} </th>
        <td mat-cell *matCellDef="let row; let rowIndex = index">
          <button type="button" mat-icon-button color="warn" (click)="removeJobStep(rowIndex)" matTooltip="{{ 'tooltips.Delete' | translate}}"
            matTooltipPosition="left">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row cdkDrag [cdkDragData]="row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div *ngIf="stepOrderHasChanged" fxLayout="row" class="container margin-t space-top" fxLayout.xs="column"
    fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button [routerLink]="['../']">
      {{"labels.buttons.Cancel" | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="saveChanges()">
      {{"labels.buttons.Apply Changes" | translate}}
    </button>
  </div>

  <div class="container space-top" *ngIf="jobStepsData.length > 0 && !stepOrderHasChanged">
    <mifosx-workflow-diagram [jobStepsData]="jobStepsData"></mifosx-workflow-diagram>
  </div>

</div>
