<button
  mat-icon-button class="ml-1" matTooltip="{{ 'tooltips.Notifications' | translate}}"
  [matMenuTriggerFor]="notificationsMenu" (menuClosed)="menuClosed()"
  [matBadge]="unreadNotifications.length" [matBadgeHidden]="!unreadNotifications.length" matBadgeColor="warn"  matBadgeSize="medium"
>
  <fa-icon icon="bell" size="lg"></fa-icon>
</button>

<mat-menu class="mifosx-notifications-menu" #notificationsMenu="matMenu" [overlapTrigger]="false">

  <div *ngIf="unreadNotifications.length === 0" fxLayout="column" fxLayoutAlign="center center" style="padding: 16px;">
    <mat-icon style="font-size: 24px; opacity: 0.8;">
      <span class="material-icons">{{'labels.menus.Notifications' | translate}}</span>
    </mat-icon>
    <p>{{ 'labels.text.No notifications' | translate }}</p>
  </div>

  <button mat-menu-item class="unread" *ngFor="let notification of unreadNotifications" [routerLink]="[routeMap[notification.objectType], notification.objectId]">
    {{ notification.content }}*<br>
    <span class="time">[{{ notification.createdAt }}]</span>
  </button>

  <button mat-menu-item *ngFor="let notification of displayedReadNotifications" [routerLink]="[routeMap[notification.objectType], notification.objectId]">
    {{ notification.content }}<br>
    <span class="time">[{{ notification.createdAt }}]</span>
  </button>

</mat-menu>
