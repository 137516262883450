
<div fxFlexFill fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious [disabled]="disablePrevious">
    <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
    Previous
  </button>
  <button mat-raised-button matStepperNext [disabled]="disableNext">
    Next
    <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
  </button>
</div>
