<div class="container">

  <mat-card>

    <form [formGroup]="centerForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="centerForm.controls.name.hasError('required')">
              {{"labels.inputs.Center Name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="centerForm.controls.name.hasError('pattern')">
              {{"labels.inputs.Center Name" | translate}} <strong>{{"labels.inputs.cannot" | translate}}</strong> {{"labels.inputs.begin with a special character or number" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="centerForm.controls.officeId.hasError('required')">
              {{"labels.inputs.Office" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Staff" | translate}}</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="active">
            {{"labels.inputs.Active" | translate}}
          </mat-checkbox>

          <mat-form-field *ngIf="this.centerForm.controls.active.value" (click)="activationDatePicker.open()">
            <mat-label>{{"labels.inputs.Activation Date" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="activationDatePicker" required formControlName="activationDate">
            <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #activationDatePicker></mat-datepicker>
            <mat-error *ngIf="centerForm.controls.activationDate.hasError('required')">
              {{"labels.inputs.Activation Date" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.External Id" | translate}}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

          <mat-form-field (click)="submittedOnDatePicker.open()">
            <mat-label>{{"labels.inputs.Submitted On" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker" required formControlName="submittedOnDate">
            <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #submittedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="centerForm.controls.submittedOnDate.hasError('required')">
              {{"labels.inputs.Submission Date" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Select and Add groups" | translate}}</mat-label>
            <mat-select [formControl]="groupChoice">
              <mat-option *ngFor="let group of groupsData" [value]="group">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div fxLayout="row">

          <div class="mat-table" *ngIf="groupChoice.value">
            <div class="mat-header-row">
              <div class="mat-header-cell">{{"labels.inputs.Group Details" | translate}}</div>
              <button mat-icon-button (click)="addGroup()">
                <fa-icon icon="plus"></fa-icon>
              </button>
            </div>
            <div class="mat-row">
              <div class="mat-cell">{{"labels.inputs.name" | translate}}</div>
              <div class="mat-cell">{{groupChoice.value.name}}</div>
            </div>
            <div class="mat-row">
              <div class="mat-cell">{{"labels.inputs.Id" | translate}}</div>
              <div class="mat-cell">{{groupChoice.value.id}}</div>
            </div>
            <div class="mat-row">
              <div class="mat-cell">{{"labels.inputs.Office" | translate}}</div>
              <div class="mat-cell">{{groupChoice.value.officeName}}</div>
            </div>
          </div>

          <mat-nav-list *ngIf="groupMembers.length">
            <h3 matSubheader>{{"labels.heading.Selected Groups" | translate}}</h3>
            <div mat-list-item *ngFor="let group of groupMembers index as i">
              <button mat-icon-button (click)="removeGroup(i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
              <span matLine>{{group.name}}</span>
            </div>
          </mat-nav-list>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.Cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!centerForm.valid" (click)="submit()" *mifosxHasPermission="'CREATE_CENTER'">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
