<div class="container">

  <mat-card>

    <form [formGroup]="userForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #userFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Username' | translate}}</mat-label>
            <input matInput required formControlName="username">
            <mat-error *ngIf="userForm.controls.username.hasError('required')">
              {{'labels.inputs.Username' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Email' | translate}}</mat-label>
            <input matInput [required]="userForm.controls.sendPasswordToEmail.value" formControlName="email">
            <mat-error *ngIf="userForm.controls.email.hasError('email')">
              {{'labels.inputs.Email' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.invalid' | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.email.hasError('required')">
              {{'labels.inputs.Email' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.First Name' | translate}}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="userForm.controls.firstname.hasError('pattern')">
              {{'labels.inputs.First Name' | translate}} <strong>{{"labels.commons.cannot begin with a special character or number" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.firstname.hasError('required')">
              {{'labels.inputs.First Name' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Last Name' | translate}}</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="userForm.controls.lastname.hasError('pattern')">
              {{'labels.inputs.Last Name' | translate}} <strong>{{"labels.commons.cannot begin with a special character or number" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.lastname.hasError('required')">
              {{'labels.inputs.Last Name' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlex="48%" class="password-never-expires-wrapper">
            <mat-checkbox labelPosition="before" formControlName="passwordNeverExpires" >
              {{'labels.inputs.Password never expires' | translate}}
            </mat-checkbox>
          </div>

          <div fxFlex="48%" class="send-password-to-email-wrapper">
            <mat-checkbox labelPosition="before" formControlName="sendPasswordToEmail">
              {{'labels.inputs.Send password to email address' | translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="48%" *ngIf="!userForm.controls.sendPasswordToEmail.value">
            <mat-label>{{'labels.inputs.Password' | translate}}</mat-label>
            <input matInput required type="password" formControlName="password">
            <mat-error *ngIf="userForm.controls.password.hasError('required')">
              {{'labels.inputs.Password' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.password.hasError('minlength')">
              {{'labels.commons.Password should be at least' | translate }} <strong>{{'labels.commons.8 characters long' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.password.hasError('maxlength')">
              {{'labels.commons.Password should not be more than' | translate }} <strong>{{'labels.commons.50 characters long' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.password.hasError('pattern') && !(userForm.controls.password.hasError('minlength') || userForm.controls.password.hasError('maxlength'))">
              {{'labels.commons.Password should include a' | translate }} <strong>{{'labels.commons.numeral' | translate }}</strong> {{'labels.commons.and at' | translate }} <strong>{{'labels.commons.least one uppercase' | translate }}</strong> ,<strong>{{'labels.commons.lowercase and special character' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="!userForm.controls.sendPasswordToEmail.value">
            <mat-label>{{'labels.inputs.Repeat Password' | translate}}</mat-label>
            <input matInput required type="password" formControlName="repeatPassword">
            <mat-error *ngIf="userForm.controls.repeatPassword.hasError('required')">
              {{'labels.inputs.Password' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <p fxFlex="98%" class="reset-password-error" *ngIf="userForm.errors?.passwordsDoNotMatch &&
          (!userForm.controls.password.hasError('required') && !userForm.controls.repeatPassword.hasError('required'))">
          {{'labels.inputs.Passwords' | translate}} <strong>{{'labels.commons.do not match' | translate}}</strong>
          </p>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Office' | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officesData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls.officeId.hasError('required')">
              {{'labels.inputs.Office' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Staff' | translate}}</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.Roles' | translate}}</mat-label>
            <mat-select required formControlName="roles" multiple>
              <mat-option *ngFor="let role of rolesData" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls.roles.hasError('required')">
              {{"labels.commons.At least one role" | translate}} <strong>{{"labels.commons.must be selected" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!userForm.valid">{{'labels.buttons.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateUserFormRef let-popover="popover">
  <h2>{{'labels.heading.Create User' | translate}}</h2>
  <p class="mw400">{{'labels.text.Filling Details' | translate}}: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67141740/Users" target="_blank">{{'labels.inputs.Users' | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{'labels.buttons.Create User' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.Next' | translate}}</button>
  </div>
</ng-template>

