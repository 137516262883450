<div class="container">

  <mat-card>

    <form [formGroup]="journalEntryForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #createJournalFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="journalEntryForm.controls.officeId.hasError('required')">
              {{"labels.inputs.Office" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Currency" | translate}}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="journalEntryForm.controls.currencyCode.hasError('required')">
              {{"labels.inputs.Currency" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="debits"
            *ngFor="let debit of debits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mifosx-gl-account-selector fxFlex="48%"
                [inputFormControl]="debits.at(i).controls.glAccountId"
                [glAccountList]="glAccountData"
                [required]="true"
                [inputLabel]="'Affected GL Entry (Debit)'"
              >
              </mifosx-gl-account-selector>

              <mat-form-field fxFlex="43%">
                <mat-label>{{"labels.inputs.Debit Amount" | translate}}</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="debits.at(i).controls.amount.hasError('required')">
                  {{"labels.inputs.Debit Amount" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(debits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(debits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="credits"
            *ngFor="let credit of credits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mifosx-gl-account-selector fxFlex="48%"
                [inputFormControl]="credits.at(i).controls.glAccountId"
                [glAccountList]="glAccountData"
                [required]="true"
                [inputLabel]="'Affected GL Entry (Credit)'"
              >
              </mifosx-gl-account-selector>

              <mat-form-field fxFlex="43%">
                <mat-label>{{"labels.inputs.Credit Amount"|translate}}</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="credits.at(i).controls.amount.hasError('required')">
                  {{"labels.inputs.Credit Amount"|translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(credits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(credits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Reference Number"|translate}}</mat-label>
            <input matInput formControlName="referenceNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="transactionDatePicker.open()">
            <mat-label>{{"labels.inputs.Transaction Date"|translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transactionDatePicker" required formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transactionDatePicker></mat-datepicker>
            <mat-error *ngIf="journalEntryForm.controls.transactionDate.hasError('required')">
              {{"labels.inputs.Transaction Date"|translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Payment Type"|translate}}</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of paymentTypeData" [value]="paymentType.id">
                {{ paymentType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Account Number"|translate}}</mat-label>
            <input matInput formControlName="accountNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Cheque Number"|translate}}</mat-label>
            <input matInput formControlName="checkNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Routing Code"|translate}}</mat-label>
            <input matInput formControlName="routingCode">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Receipt Number"|translate}}</mat-label>
            <input matInput formControlName="receiptNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Bank Number"|translate}}</mat-label>
            <input matInput formControlName="bankNumber">
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{"labels.inputs.Comments"|translate}}</mat-label>
            <textarea matInput formControlName="comments" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!journalEntryForm.valid" *mifosxHasPermission="'CREATE_JOURNALENTRY'">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCreateJournalFormRef let-popover="popover">
  <h2>{{"labels.heading.Add Journal Entry Form" | translate}}</h2>
  <p class="mw300">{{"labels.text.Red asterisk field" | translate}} <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895310/Add+Journal+Entries" target="_blank">{{"labels.inputs.Add Journal Entries" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.Close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.Back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.Next" | translate}}</button>
  </div>
</ng-template>
