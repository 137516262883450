<div class="container">

    <mat-card>

        <form [formGroup]="createSelfServiceForm" (ngSubmit)="submit()">

            <mat-card-content>

                <div div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{"labels.inputs.User Name" | translate}} </mat-label>
                        <input matInput required formControlName="username">
                        <mat-error *ngIf="createSelfServiceForm.controls.username.hasError('required')">
                            {{"labels.inputs.User Name" | translate}} {{"labels.commons.is" | translate}}
                            <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{"labels.inputs.First Name" | translate}} </mat-label>
                        <input matInput required formControlName="firstname">
                        <mat-error *ngIf="createSelfServiceForm.controls.firstname.hasError('required')">
                            {{"labels.inputs.First Name" | translate}} {{"labels.commons.is" | translate}}
                            <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{"labels.inputs.Last Name" | translate}} </mat-label>
                        <input matInput required formControlName="lastname">
                        <mat-error *ngIf="createSelfServiceForm.controls.lastname.hasError('required')">
                            {{"labels.inputs.Last Name" | translate}} {{"labels.commons.is" | translate}}
                            <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{"labels.inputs.Email" | translate}} </mat-label>
                        <input matInput required formControlName="email">
                        <mat-error *ngIf="createSelfServiceForm.controls.email.hasError('required')">
                            {{"labels.inputs.Email" | translate}} {{"labels.commons.is" | translate}}
                            <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.email.hasError('email')">
                            {{"labels.inputs.Email should be a" | translate}} <strong>{{"labels.inputs.valid" |
                                translate}}</strong> {{"labels.inputs.email" | translate}}.
                        </mat-error>
                    </mat-form-field>

                    <mat-checkbox labelPosition="before" formControlName="sendPasswordToEmail" fxFlex="48%">
                        {{"labels.inputs.Auto Generate Password" | translate}}
                    </mat-checkbox>

                    <div fxFlex="48%" fxLayoutGap="5px" fxLayout="row">

                        <mat-checkbox labelPosition="before" formControlName="passwordNeverExpires">
                            {{"labels.inputs.Override Password Expire Policy" | translate}} ?
                        </mat-checkbox>

                        <i class="fa fa-question-circle fa fa-align-left"
                            title="Determines if passwords do not expire for this user even if global configuration for password expiry is enabled"></i>

                    </div>

                    <mat-form-field fxFlex="48%" *ngIf="!hidePasswordField">
                        <mat-label>{{ 'labels.inputs.Password' | translate }}</mat-label>
                        <input matInput formControlName="password" type="password">
                        <mat-error *ngIf="createSelfServiceForm.controls.password.hasError('required')">
                            {{ 'labels.inputs.Password' | translate }} <strong>{{'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.password.hasError('minlength')">
                            {{'labels.commons.Password should be at least' | translate }} <strong>{{'labels.commons.12 characters long' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.password.hasError('maxlength')">
                            {{'labels.commons.Password should not be more than' | translate }}
                            <strong>{{'labels.commons.50 characters long' | translate }}</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="createSelfServiceForm.controls.password.hasError('pattern') && !(createSelfServiceForm.controls.password.hasError('minlength') || createSelfServiceForm.controls.password.hasError('maxlength'))">
                            {{'labels.commons.Password should include a' | translate }}
                            <strong>{{'labels.commons.numeral' | translate }}</strong> {{'labels.commons.and at' | translate }}
                            <strong>{{'labels.commons.least one uppercase' | translate }}</strong>
                            ,<strong>{{'labels.commons.lowercase and special character' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%" *ngIf="!hidePasswordField">
                        <mat-label> {{"labels.inputs.Repeat Password" | translate}} </mat-label>
                        <input matInput required formControlName="repeatPassword" type="password">
                        <mat-error *ngIf="createSelfServiceForm.controls.repeatPassword.hasError('required')">
                            {{"labels.inputs.Repeat password" | translate}} {{"labels.commons.is" | translate}}
                            <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.repeatPassword.hasError('notequal')">
                            {{"labels.inputs.Passwords should be" | translate}} <strong>{{"labels.inputs.matched" |
                                translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{"labels.inputs.Status" | translate}} </mat-label>
                        <input matInput formControlName="status">
                    </mat-form-field>

                </div>

                <mat-card-actions fxLayoutGap="5px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
                    <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel" |
                        translate}}</button>
                    <button mat-raised-button color="primary"
                        [disabled]="!createSelfServiceForm.valid">{{"labels.buttons.Activate Self Service User" |
                        translate}}</button>
                    <i class="fa fa-question-circle fa-2x" title="Activate Self Service User Help"></i>
                </mat-card-actions>

            </mat-card-content>

        </form>

    </mat-card>

</div>
