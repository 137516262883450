<div class="container">

  <mat-card>

    <form [formGroup]="allocateCashForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Office' | translate }}</mat-label>
            <input matInput required formControlName="office">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Teller Name' | translate }}</mat-label>
            <input matInput required formControlName="tellerName">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Cashier' | translate }}</mat-label>
            <input matInput required formControlName="cashier">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Assignment Period' | translate }}</mat-label>
            <input matInput formControlName="assignmentPeriod">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="cashDatePicker.open()">
            <mat-label>{{ 'labels.inputs.Date' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="cashDatePicker" required formControlName="txnDate">
            <mat-datepicker-toggle matSuffix [for]="cashDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #cashDatePicker></mat-datepicker>
            <mat-error *ngIf="allocateCashForm.controls.txnDate.hasError('required')">
              {{ 'labels.inputs.Date' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Currency' | translate }}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of cashierData.currencyOptions" [value]="currency.code">
                {{ currency.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="allocateCashForm.controls.currencyCode.hasError('required')">
              {{ 'labels.inputs.Currency' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Amount' | translate }}</mat-label>
            <input matInput required formControlName="txnAmount">
            <mat-error *ngIf="allocateCashForm.controls.txnAmount.hasError('required')">
              {{ 'labels.inputs.Amount' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.Notes/Comments' | translate }}</mat-label>
            <input matInput required formControlName="txnNote">
            <mat-error *ngIf="allocateCashForm.controls.txnNote.hasError('required')">
              {{ 'labels.inputs.Notes/Comments' | translate }} {{'labels.commons.is' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.Cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!allocateCashForm.valid" *mifosxHasPermission="'ALLOCATECASHIER_TELLER'">{{ 'labels.buttons.Submit' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
