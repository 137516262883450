<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%">

  <mat-form-field [fxFlex]="flex || '83%'">
    <input matInput [readonly]="true" [value]="fileName || ''" placeholder="No file selected">
  </mat-form-field>

  <span fxFlex="10%" fxFlexAlign="center">
    <button mat-button (click)="uploadFile.click()">
      <fa-icon icon="folder-open" size="lg"></fa-icon>
      &nbsp;&nbsp;
      {{ 'labels.buttons.Browse' | translate }}
    </button>
  </span>

</div>

<input type="file" #uploadFile accept="{{acceptFilter}}" (change)="onFileSelect($event)" [style.display]="'none'">
