<form [formGroup]="fixedDepositProductTermsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">{{'labels.inputs.Deposit Amount' | translate}}</h4>

    <mat-form-field fxFlex="31%">
      <mat-label>{{'labels.inputs.Minimum' | translate}}</mat-label>
      <input type="number" matInput formControlName="minDepositAmount" matTooltip="{{ 'tooltips.The minimum deposit amount required to open a fixed deposit' | translate}}">
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{'labels.inputs.Default' | translate}}</mat-label>
      <input type="number" matInput formControlName="depositAmount" matTooltip="{{ 'tooltips.The default deposit amount expected' | translate}}" required>
      <mat-error>
        {{'labels.inputs.Default Deposit Amount' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{'labels.inputs.Maximum' | translate}}</mat-label>

      <input type="number" matInput   formControlName="maxDepositAmount" matTooltip="{{ 'tooltips.The maximum deposit amount allowed when a fixed deposit' | translate}}">
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Interest Compounding Period' | translate}}</mat-label>
      <mat-select formControlName="interestCompoundingPeriodType" matTooltip="{{ 'tooltips.The period at which interest rate' | translate}}" required>
        <mat-option *ngFor="let interestCompoundingPeriodType of interestCompoundingPeriodTypeData" [value]="interestCompoundingPeriodType.id">
          {{ interestCompoundingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Interest Compounding Period' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Interest Posting Period' | translate}}</mat-label>
      <mat-select formControlName="interestPostingPeriodType" matTooltip="{{ 'tooltips.The period at which interest rate is posted or credited' | translate}}" required>
        <mat-option *ngFor="let interestPostingPeriodType of interestPostingPeriodTypeData" [value]="interestPostingPeriodType.id">
          {{ interestPostingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Interest Posting Period' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Interest Calculated using' | translate}}</mat-label>
      <mat-select formControlName="interestCalculationType" matTooltip="{{ 'tooltips.The method used to calculate interest' | translate}}" required>
        <mat-option *ngFor="let interestCalculationType of interestCalculationTypeData" [value]="interestCalculationType.id">
          {{ interestCalculationType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Interest Calculated using' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Days in Year' | translate}}</mat-label>
      <mat-select formControlName="interestCalculationDaysInYearType" matTooltip="{{ 'tooltips.The setting for number of days' | translate}}" required>
        <mat-option *ngFor="let interestCalculationDaysInYearType of interestCalculationDaysInYearTypeData" [value]="interestCalculationDaysInYearType.id">
          {{ interestCalculationDaysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Days in Year' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{'labels.buttons.Previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{'labels.buttons.Next' | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
