<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_TEMPLATE'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate}}
  </button>

  <button mat-raised-button color="warn" (click)="delete()" *mifosxHasPermission="'DELETE_TEMPLATE'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{'labels.buttons.Delete' | translate}}
  </button>

</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.name' | translate}}
        </div>

        <div fxFlex="50%">
          {{ templateData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Entity' | translate}}
        </div>

        <div fxFlex="50%">
          {{ templateData.entity }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Type' | translate}}
        </div>

        <div fxFlex="50%">
          {{ templateData.type }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.Text' | translate}}
        </div>

        <div fxFlex="50%" [innerHTML]="templateData.text"></div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
