<div class="container">

  <mat-card fxLayout="column" fxLayoutGap="2%" *ngIf="hasResults">

    <mat-error *ngIf="overload">{{ 'error.Searched query resulted more than 200 records' | translate}}</mat-error>

    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="entityType">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Entity Type' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details"> {{entity.entityType}} </td>
      </ng-container>

      <ng-container matColumnDef="entityName">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Entity Name' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details"> {{entity.entityName}} </td>
      </ng-container>

      <ng-container matColumnDef="entityAccount">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Account No' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details">
          <mifosx-account-number display="left" accountNo="{{entity.entityAccountNo}}"></mifosx-account-number>
        </td>
      </ng-container>

      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.External Id' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details">
          <mifosx-external-identifier externalId="{{entity.entityExternalId}}"></mifosx-external-identifier>
        </td>
      </ng-container>

      <ng-container matColumnDef="parentType">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Parent Type' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details"> {{ ['CLIENT','GROUP', 'CENTER'].includes(entity.entityType) ? 'Office' : 'Client' }} </td>
      </ng-container>

      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Parent Name' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details"> {{entity.parentName}} </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Details' | translate}} </th>
        <td mat-cell *matCellDef="let entity" class="view-details">
          <button mat-icon-button matTooltip="{{ 'tooltips.View Entity' | translate}}" (click)="navigate(entity)" matTooltipPosition="right">
            <fa-icon icon="eye" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let entity; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </mat-card>

  <mat-card fxLayout="column" fxLayoutGap="2%" *ngIf="!hasResults">
    <div class="alert">

      <div class="message">
        <i class="fa fa-exclamation-circle alert-check"></i>
        {{'labels.text.No data found' | translate}}
      </div>

    </div>
  </mat-card>

</div>
