<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['pay']" *mifosxHasPermission="'PAY_CLIENTCHARGE'">
    <fa-icon icon="dollar-sign" class="m-r-10"></fa-icon>
    {{"labels.buttons.Pay" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="waiveCharge()" *mifosxHasPermission="'WAIVE_CLIENTCHARGE'">
    <i class="fa fa-flag"></i>
    {{"labels.buttons.Waive Charge" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="deleteCharge()">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{"labels.buttons.Delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-title> <i class="fa fa-stop" [ngClass]="(!(chargeData.isWaived || chargeData.isPaid))|statusLookup"></i>
      {{ chargeData.name }}
    </mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>

      <div class="share-account-tables p-t-20" fxLayout="row wrap" fxFlex="50%">

        <table>

          <tbody>

            <tr>
              <td> {{"labels.inputs.Currency" | translate}} </td>
              <td> {{ chargeData.currency.name }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Charge Time Type" | translate}} </td>
              <td> {{ chargeData.chargeTimeType.value }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Charge Calculation Type" | translate}} </td>
              <td> {{ chargeData.chargeCalculationType.value }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Due as of" | translate}} </td>
              <td> {{ chargeData.dueDate | dateFormat }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Due" | translate}} </td>
              <td> {{ chargeData.amount }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Paid" | translate}} </td>
              <td> {{ chargeData.amountPaid }} </td>
            </tr>

            <tr>
              <td> {{"labels.inputs.Waived" | translate}} </td>
              <td> {{ chargeData.amountWaived }} </td>
            </tr>
            <tr>
              <td> {{"labels.inputs.Outstanding" | translate}} </td>
              <td> {{ chargeData.amountOutstanding }} </td>
            </tr>

          </tbody>

        </table>

      </div>

    </mat-card-content>

    <div fxLayout="row wrap" class="content" style="padding-top:10px">
      <h1><b> {{"labels.heading.Transactions" | translate}} </b></h1>

      <table mat-table [dataSource]="chargeData.clientTransactionDatas" *ngIf="chargeData.clientTransactionDatas">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.ID" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="officeName">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Office Name" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.officeName }} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Type" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.type.value}} </td>
        </ng-container>
        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Transaction Date" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.date | dateFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Amount" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.amount | formatNumber }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Actions" | translate}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}">
            <button mat-raised-button color="primary" (click)="undoTransaction(element.id)" class="delBtn">
              <fa-icon icon="trash" class="m-r-10"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="viewChargeTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: viewChargeTableColumns;"></tr>
      </table>

    </div>


    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../../','general']">{{"labels.buttons.Back" | translate}}</button>
    </mat-card-actions>

  </mat-card>

</div>
