<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">

      <div fxFlex="50%">

        <mat-nav-list>

          <div #office>
            <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
              <mat-icon matListIcon [routerLink]="['offices']">
                <fa-icon icon="building" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['offices']">{{ 'labels.heading.Manage Offices' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="!arrowBooleans[0]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="arrowBooleans[0]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[0]" [routerLink]="['offices']">{{ 'labels.text.Add new office or modify or deactivate office' | translate }}</p>
            </mat-list-item>
          </div>

          <div #holidays>
            <mat-list-item *mifosxHasPermission="'READ_HOLIDAY'">
              <mat-icon matListIcon [routerLink]="['holidays']">
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['holidays']">{{ 'labels.heading.Manage Holidays' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="!arrowBooleans[1]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="arrowBooleans[1]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[1]" [routerLink]="['holidays']">{{ 'labels.text.Define holidays for office' | translate }}</p>
            </mat-list-item>
          </div>

          <div #employee>
            <mat-list-item *mifosxHasPermission="'READ_STAFF'">
              <mat-icon matListIcon [routerLink]="['employees']">
                <fa-icon icon="user" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['employees']">{{ 'labels.heading.Manage Employees' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="!arrowBooleans[2]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="arrowBooleans[2]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[2]" [routerLink]="['employees']">{{ 'labels.text.Employee represents loan officers' | translate }}</p>
            </mat-list-item>
          </div>

          <div #standinginstructions>
            <mat-list-item
              *mifosxHasPermission="'READ_STANDINGINSTRUCTION'">
              <mat-icon matListIcon [routerLink]="['standing-instructions-history']">
                <fa-icon icon="book" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['standing-instructions-history']">{{ 'labels.heading.Standing Instructions History' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="!arrowBooleans[3]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="arrowBooleans[3]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[3]" [routerLink]="['standing-instructions-history']">{{ 'labels.text.View logged history' | translate }}</p>
            </mat-list-item>
          </div>

          <div #investor>
            <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
              <mat-icon matListIcon [routerLink]="['investors']">
                <fa-icon icon="user-tie" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['investors']">{{ 'labels.text.Investors' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="!arrowBooleans[4]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="arrowBooleans[4]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[4]" [routerLink]="['investors']">{{ 'labels.text.View the loan account details associated with the investors' | translate }}</p>
            </mat-list-item>
          </div>

          <div #fundmapping>
            <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
              <mat-icon matListIcon [routerLink]="['fund-mapping']">
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['fund-mapping']">{{ 'labels.heading.Fund Mapping' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="!arrowBooleans[5]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="arrowBooleans[5]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[5]" [routerLink]="['fund-mapping']">{{ 'labels.text.Bulk entry screen for mapping' | translate }}</p>
            </mat-list-item>
          </div>

          <div #passwordpreferences>
            <mat-list-item
              *mifosxHasPermission="'READ_PASSWORD_VALIDATION_POLICY'">
              <mat-icon matListIcon [routerLink]="['password-preferences']">
                <fa-icon icon="lock" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['password-preferences']">{{ 'labels.heading.Password Preferences' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="!arrowBooleans[6]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="arrowBooleans[6]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[6]" [routerLink]="['password-preferences']">{{ 'labels.text.The usage of stronger passwords' | translate }}</p>
            </mat-list-item>
          </div>

          <div #provisioning>
            <mat-list-item *mifosxHasPermission="'VIEW_PROVISIONS'">
              <mat-icon matListIcon [routerLink]="['provisioning-criteria']">
                <fa-icon icon="edit" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['provisioning-criteria']">{{ 'labels.heading.Loan Provisioning Criteria' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="!arrowBooleans[7]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="arrowBooleans[7]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[7]" [routerLink]="['provisioning-criteria']">{{ 'labels.text.Loan Provisioning Criteria Organization' | translate }}</p>
            </mat-list-item>
          </div>

          <div #entitydatatable>
            <mat-list-item
              *mifosxHasPermission="'READ_ENTITY_DATATABLE_CHECK'">
              <mat-icon matListIcon [routerLink]="['entity-data-table-checks']">
                <fa-icon icon="check" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['entity-data-table-checks']">{{ 'labels.heading.Entity Data Table Checks' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="!arrowBooleans[8]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="arrowBooleans[8]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[8]" [routerLink]="['entity-data-table-checks']">{{ 'labels.text.Entity Data Table Checks Organization' | translate }}</p>
            </mat-list-item>
          </div>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <div #addEditCurrency>
            <mat-list-item *mifosxHasPermission="'READ_CURRENCY'">
              <mat-icon matListIcon [routerLink]="['currencies']">
                <fa-icon icon="cogs" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['currencies']">{{ 'labels.heading.Currency Configuration' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="!arrowBooleans[9]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="arrowBooleans[9]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[9]" [routerLink]="['currencies']">{{ 'labels.text.Currencies available across organization' | translate }}</p>
            </mat-list-item>
          </div>

          <div #manageFunds>
            <mat-list-item *mifosxHasPermission="'READ_FUND'">
              <mat-icon matListIcon [routerLink]="['manage-funds']">
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['manage-funds']">{{ 'labels.heading.Manage Funds' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="!arrowBooleans[10]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="arrowBooleans[10]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[10]" [routerLink]="['manage-funds']">{{ 'labels.text.Funds are associated with loans' | translate }}</p>
            </mat-list-item>
          </div>

          <div #bulkloan>
            <mat-list-item *mifosxHasPermission="'BULKREASSIGN_LOAN'">
              <mat-icon matListIcon [routerLink]="['bulkloan']">
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['bulkloan']">{{ 'labels.heading.Bulk Loan Reassignment' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(11)" *ngIf="!arrowBooleans[11]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(11)" *ngIf="arrowBooleans[11]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[11]" [routerLink]="['bulkloan']">{{ 'labels.text.Easy way to reassign all the loan' | translate }}</p>
            </mat-list-item>
          </div>

          <div #tellers>
            <mat-list-item *mifosxHasPermission="'READ_TELLER'">
              <mat-icon matListIcon [routerLink]="['tellers']">
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['tellers']">{{ 'labels.heading.Teller / Cashier Management' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(12)" *ngIf="!arrowBooleans[12]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(12)" *ngIf="arrowBooleans[12]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[12]" [routerLink]="['tellers']">{{ 'labels.text.Manage Tellers / Cashiers' | translate }}</p>
            </mat-list-item>
          </div>

          <div #workingDays>
            <mat-list-item *mifosxHasPermission="'READ_WORKINGDAYS'">
              <mat-icon matListIcon [routerLink]="['working-days']">
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['working-days']">{{ 'labels.heading.Working Days' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(13)" *ngIf="!arrowBooleans[13]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(13)" *ngIf="arrowBooleans[13]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[13]" [routerLink]="['working-days']">{{ 'labels.text.Working days and configure behaviour of payments' | translate }}</p>
            </mat-list-item>
          </div>

          <div #paymenttypes>
            <mat-list-item *mifosxHasPermission="'READ_PAYMENTTYPE'">
              <mat-icon matListIcon [routerLink]="['payment-types']">
                <fa-icon icon="dollar-sign" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['payment-types']">{{ 'labels.heading.Payment Type' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(14)" *ngIf="!arrowBooleans[14]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(14)" *ngIf="arrowBooleans[14]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[14]" [routerLink]="['payment-types']">{{ 'labels.text.Manage payment types' | translate }}</p>
            </mat-list-item>
          </div>

          <div #smscampaigns>
            <mat-list-item *mifosxHasPermission="'VIEW_SMSCAMPAIGNS'">
              <mat-icon matListIcon [routerLink]="['sms-campaigns']">
                <fa-icon icon="envelope" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['sms-campaigns']">{{ 'labels.heading.SMS Campaigns' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(15)" *ngIf="!arrowBooleans[15]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(15)" *ngIf="arrowBooleans[15]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[15]" [routerLink]="['sms-campaigns']">{{ 'labels.text.Define SMS Campaigns for Organization' | translate }}</p>
            </mat-list-item>
          </div>

          <div #adhocquery>
            <mat-list-item *mifosxHasPermission="'VIEW_ADHOC'">
              <mat-icon matListIcon [routerLink]="['adhoc-query']">
                <fa-icon icon="chevron-right" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['adhoc-query']">{{ 'labels.heading.AdHocQuery' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(16)" *ngIf="!arrowBooleans[16]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(16)" *ngIf="arrowBooleans[16]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[16]" [routerLink]="['adhoc-query']">{{ 'labels.text.Define AdHocQuery for Organization' | translate }}</p>
            </mat-list-item>
          </div>

          <div #bulkimport>
            <mat-list-item *mifosxHasPermission="'VIEW_BULKIMPORT'">
              <mat-icon matListIcon [routerLink]="['bulk-import']">
                <fa-icon icon="upload" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine [routerLink]="['bulk-import']">{{ 'labels.heading.Bulk Import' | translate }}</h4>
              <fa-icon (click)="arrowBooleansToggle(17)" *ngIf="!arrowBooleans[17]" icon="arrow-down" size="md"></fa-icon>
              <fa-icon (click)="arrowBooleansToggle(17)" *ngIf="arrowBooleans[17]" icon="arrow-up" size="md"></fa-icon>
              <p matLine *ngIf="arrowBooleans[17]" [routerLink]="['bulk-import']">{{ 'labels.text.Bulk data import using excel spreadsheet templates' | translate }}</p>
            </mat-list-item>
          </div>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateOffice let-popover="popover">
  <h2>{{ 'labels.heading.Manage Offices' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.Manage Offices page allows you to create, edit' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepOffice()">{{ 'labels.buttons.Back' |
      translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepOffice()">{{ 'labels.buttons.Next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateAddEditCurrency let-popover="popover">
  <h2>{{ 'labels.heading.Currency Configuration' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.Define what currencies interactions within the Mifos X system' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepAddEditCurrency()">{{
      'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepAddEditCurrency()">{{
      'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateHolidays let-popover="popover">
  <h2>{{ 'labels.heading.Manage Holidays' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.Ability to manage holidays for individual offices' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepHolidays()">{{ 'labels.buttons.Back'
      | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepHolidays()">{{ 'labels.buttons.Next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateEmployee let-popover="popover">
  <h2>{{ 'labels.heading.Manage Employees' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.View, edit and/or delete your organization's employees' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepEmployee()">{{ 'labels.buttons.Back'
      | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepEmployee()">{{ 'labels.buttons.Next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateWorkingDays let-popover="popover">
  <h2>{{ 'labels.heading.Working Days' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.Working days page allows you to define which days' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepWorkingDays()">{{
      'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepWorkingDays()">{{ 'labels.buttons.Next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateManageFunds let-popover="popover">
  <h2>{{ 'labels.heading.Manage Funds' | translate }}</h2>
  <p class="mw300">{{ 'labels.text.This allows you to manage funds associated with loans.' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepManageFunds()">{{
      'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepManageFunds()">{{ 'labels.buttons.Next' |
      translate }}</button>
  </div>
</ng-template>
