<div class="tab-content mat-typography">

  <mat-list>

    <mat-list-item>
      {{ 'labels.inputs.Campaign Name' | translate }} : {{ campaign.campaignName }}
    </mat-list-item>

    <mat-list-item>
      {{ 'labels.inputs.SMS Provider' | translate }} : {{ (campaign.providerId | find:smsProviders:'id':'name') || "Unassigned" }}
    </mat-list-item>

    <mat-list-item>
      {{ 'labels.inputs.Trigger Type' | translate }} : {{ (campaign.editFlag ? campaign.triggerType.id : campaign.triggerType) | find:triggerTypes:'id':'value' }}
    </mat-list-item>

    <mat-list-item>
      {{ 'labels.inputs.Bussiness Rule' | translate }} : {{ campaign.editFlag ? campaign.reportName : campaign.paramValue?.reportName }}
    </mat-list-item>

    <div fxLayout="column" fxLayoutGap="10px" class="template-message">
      <h3>{{ 'labels.heading.Campaign Message' | translate }} :</h3>
      <textarea matInput disabled>{{ campaign.editFlag ? editedCampaignMessage : campaign.message }}</textarea>
    </div>

  </mat-list>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button [routerLink]="['../']">
      {{'labels.buttons.Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="submitEvent.emit()">
      {{'labels.buttons.Submit' | translate }}
    </button>
  </div>

</div>
