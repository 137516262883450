<mat-card-header fxLayout="row" fxLayoutGap="5%" class="header">
  <fa-icon class="main-icon" icon="building" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ centerData.name }}
          <span [className]="centerData.status.code | statusLookup">
            <fa-icon matTooltip="{{ centerData.status.value }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </span>
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p>
          {{"labels.inputs.Account No" | translate}}:
          <mifosx-account-number accountNo="{{centerData.accountNo}}"></mifosx-account-number>
        </p>
        <p *ngIf="centerData.externalId">
          {{"labels.inputs.External Id" | translate}}:
          <mifosx-external-identifier externalId="{{centerData.externalId}}"></mifosx-external-identifier>
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>

  <mat-tab-group>
    <mat-tab label="Details">
      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.Activation Date" | translate}}
        </div>

        <div fxFlex="50%">
          {{ centerData.activationDate | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.Associated Officer" | translate}}
        </div>

        <div fxFlex="50%">
          {{ centerData.staffName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.inputs.Number of Active Clients" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClients }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.inputs.Number of Active Client Loans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClientLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.inputs.Number of Active Client Borrowers" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClientBorrowers }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.inputs.Number of Active Overdue Group Loans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.overdueGroupLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.inputs.Number of Active Overdue Client Loans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.overdueClientLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.nextTenRecurringDates[0]">
          {{"labels.inputs.Next Meeting Date" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.nextTenRecurringDates[0]">
          {{ centerSummaryData.collectionMeetingCalendar.nextTenRecurringDates[0] | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.humanReadable">
          {{"labels.inputs.Meeting Frequency" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.humanReadable">
          {{ centerSummaryData.collectionMeetingCalendar.humanReadable }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.Number of Groups" | translate}}
        </div>

        <div fxFlex="50%">
          {{ groupData ? groupData.length : '' }}
        </div>

      </div>
    </mat-tab>

    <mat-tab label="Loan Accounts" *ngIf="centerAccountsData && centerAccountsData.loanAccounts">
      <mifosx-loan-account-table [loanAccountData]="centerAccountsData.loanAccounts"></mifosx-loan-account-table>
    </mat-tab>

    <mat-tab label="Savings Accounts" *ngIf="centerAccountsData && centerAccountsData.savingsAccounts">
      <mifosx-savings-account-table [savingsAccountData]="centerAccountsData.savingsAccounts"></mifosx-savings-account-table>
    </mat-tab>

  </mat-tab-group>

</mat-card-content>
