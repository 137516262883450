<div class="container">

  <mat-card>

    <form [formGroup]="floatingRateForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15%">

          <mat-form-field fxFlex="25%">
            <mat-label>{{'labels.inputs.Floating Rate Name' | translate}}</mat-label>
            <input matInput required matTooltip="{{ 'tooltips.Unique name for the floating rate scheme' | translate}}" formControlName="name">
            <mat-error *ngIf="floatingRateForm.controls.name.hasError('required')">
              {{'labels.inputs.Floating Rate Name' | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div class="checkbox-wrapper" fxFlex="25%">
            <mat-checkbox formControlName="isBaseLendingRate" matTooltip="{{ 'tooltips.Base lending rate for the organisation' | translate}}" class="checkbox" labelPosition="before">
              {{'labels.inputs.Is Base Lending Rate' | translate}}?
            </mat-checkbox>
          </div>

          <div class="checkbox-wrapper" fxFlex="15%">
            <mat-checkbox formControlName="isActive" matTooltip="{{ 'tooltips.Floating rate scheme to be activated' | translate}}" class="checkbox" labelPosition="before">
              {{'labels.inputs.Active' | translate}}
            </mat-checkbox>
          </div>

        </div>

        <br>

        <mat-divider [inset]="true"></mat-divider>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="70%" fxLayoutGap.lt-md="0px" class="m-b-10" fxLayout.lt-md="column">

          <p fxFlex="25%" class="mat-title">{{'labels.inputs.Floating Rate Periods' | translate}} <i class="fas fa-question" matTooltip="{{ 'tooltips.Floating interest rate and start date for this floating rate scheme' | translate}}"></i></p>

          <button mat-mini-fab type="button" color="primary" (click)="addFloatingRatePeriod()">
            <fa-icon icon="plus"></fa-icon>
          </button>

        </div>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'tooltips.Interest effective from date' | translate}}" mat-sort-header> {{'labels.inputs.From Date' | translate}} </th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.fromDate | dateFormat }} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'tooltips.Annualised Interest rate' | translate}}" mat-sort-header> {{'labels.inputs.Interest Rate' | translate}} </th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.interestRate }} </td>
          </ng-container>

          <ng-container matColumnDef="isDifferential">
            <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'tooltips.Interest rate mentioned is added to base lending rate' | translate}}" mat-sort-header> {{'labels.inputs.Is Differential' | translate}}? </th>
            <td mat-cell *matCellDef="let floatingRate">
              <fa-icon *ngIf="floatingRate.isDifferentialToBaseLendingRate" icon="check-circle" size="lg"
                class="is-differential" matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!floatingRate.isDifferentialToBaseLendingRate" icon="times-circle" size="lg"
                class="not-is-differential" matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.Actions' | translate}} </th>
            <td mat-cell *matCellDef="let floatingRatePeriod">
              <button type="button" color="primary" mat-icon-button
                (click)="editFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" color="warn" mat-icon-button
                (click)="deleteFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button type="submit" color="primary" [disabled]="!floatingRateForm.valid || floatingRateForm.pristine && isFloatingRateFormPristine">
          {{'labels.buttons.Submit' | translate}}
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
