<p>{{"labels.text.Please update your password" | translate}}:</p>

<mat-divider></mat-divider>

<form fxLayout="column" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" id="reset-password-form">

  <mat-form-field fxFlexAlign="center" class="reset-password-input">
    <span matPrefix>
      <fa-icon icon="lock" size="lg" class="m-r-10"></fa-icon>
    </span>
    <mat-label>{{'labels.inputs.Password' | translate}}</mat-label>
    <input type="{{ passwordInputType }}" matInput required formControlName="password">
    <button mat-button *ngIf="resetPasswordForm.controls.password.value && !loading" matSuffix mat-icon-button
      (mousedown)="passwordInputType = 'text'" (mouseup)="passwordInputType = 'password'">
      <fa-icon *ngIf="passwordInputType === 'password'" icon="eye"></fa-icon>
      <fa-icon *ngIf="passwordInputType === 'text'" icon="eye-slash"></fa-icon>
    </button>
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('required')">
      {{'labels.inputs.Password' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlexAlign="center" class="reset-password-input">
    <span matPrefix>
      <fa-icon icon="check-circle" size="lg" class="m-r-10"></fa-icon>
    </span>
    <mat-label>{{'labels.inputs.Confirm Password' | translate}}</mat-label>
    <input type="password" matInput required formControlName="repeatPassword">
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('required')">
      {{ 'labels.inputs.Password' | translate }} <strong>{{'labels.commons.is required' | translate }}</strong>
    </mat-error>
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('minlength')">
      {{'labels.commons.Password should be at least' | translate }} <strong>{{'labels.commons.8 characters long' | translate }}</strong>
    </mat-error>
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('maxlength')">
      {{'labels.commons.Password should not be more than' | translate }} <strong>{{'labels.commons.50 characters long' | translate }}</strong>
    </mat-error>
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('pattern') && !(resetPasswordForm.controls.password.hasError('minlength') || resetPasswordForm.controls.password.hasError('maxlength'))">
      {{'labels.commons.Password should include a' | translate }} <strong>{{'labels.commons.numeral' | translate }}</strong> {{'labels.commons.and at' | translate }} <strong>{{'labels.commons.least one uppercase' | translate }}</strong> ,<strong>{{'labels.commons.lowercase and special character' | translate }}</strong>
    </mat-error>
  </mat-form-field>

  <p class="reset-password-error" *ngIf="resetPasswordForm.errors?.passwordsDoNotMatch &&
    (!resetPasswordForm.controls.password.hasError('required') && !resetPasswordForm.controls.repeatPassword.hasError('required'))">
    {{"labels.text.Passwords" | translate}} <strong>{{"labels.text.do not match" | translate}}</strong>
  </p>

  <button mat-raised-button color="primary" fxFlexAlign="center" class="reset-password-button" [disabled]="!resetPasswordForm.valid">
    {{"labels.buttons.Reset Password" | translate}}
    <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
  </button>

</form>
