<mat-card-header fxLayout="row" fxLayoutGap="5%" class="header">
  <fa-icon class="main-icon" icon="building" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ officeData.name }}
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p *ngIf="officeData.externalId">
          {{"labels.inputs.External Id" | translate}}:<mifosx-external-identifier externalId="{{officeData.externalId}}"></mifosx-external-identifier>
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>
  <div fxLayout="row wrap" class="content">

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.inputs.Opened On" | translate}}
    </div>

    <div fxFlex="50%">
      {{ officeData.openingDate | dateFormat }}
    </div>

    <div fxFlex="50%" class="mat-body-strong" *ngIf="officeData.parentName">
      {{"labels.inputs.Parent officeData" | translate}}
    </div>

    <div fxFlex="50%" *ngIf="officeData.parentName">
      {{ officeData.parentName }}
    </div>

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.inputs.Number of Staff" | translate}}
    </div>

    <div fxFlex="50%">
      {{ employeeData ? employeeData.length : '' }}
    </div>

  </div>
</mat-card-content>
