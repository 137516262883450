<form [formGroup]="fixedDepositProductSettingsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">{{'labels.inputs.Lock-in Period' | translate}} <i class='fas fa-question' matTooltip="{{ 'tooltips.A period of time during which a fixed deposit account' | translate}}"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Frequency' | translate}}</mat-label>
      <input type="number" matInput formControlName="lockinPeriodFrequency">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Type' | translate}}</mat-label>
      <mat-select formControlName="lockinPeriodFrequencyType">
        <mat-option *ngFor="let lockinPeriodFrequencyType of lockinPeriodFrequencyTypeData" [value]="lockinPeriodFrequencyType.id">
          {{ lockinPeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{'labels.inputs.Minimum Deposit Term' | translate}} <i class='fas fa-question' matTooltip="{{ 'tooltips.The minimum length of time' | translate}}"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Frequency' | translate}}</mat-label>
      <input type="number" matInput formControlName="minDepositTerm" required>
      <mat-error>
        {{'labels.inputs.Minimum Deposit Term Frequency' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Frequency Type' | translate}}</mat-label>
      <mat-select formControlName="minDepositTermTypeId" required>
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Minimum Deposit Term Frequency Type' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{'labels.heading.And thereafter, in Multiples of' | translate}} <i class='fas fa-question-circle' matTooltip="{{ 'tooltips.After the minimum deposit term has passed' | translate}}"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Frequency' | translate}}</mat-label>
      <input type="number" matInput formControlName="inMultiplesOfDepositTerm">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Type' | translate}}</mat-label>
      <mat-select formControlName="inMultiplesOfDepositTermTypeId">
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{'labels.inputs.Maximum Deposit Term' | translate}} <i class='fas fa-question-circle' matTooltip="{{ 'tooltips.The maximum length of time funds' | translate}}"></i></h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Frequency' | translate}}</mat-label>
      <input type="number" matInput formControlName="maxDepositTerm">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Type' | translate}}</mat-label>
      <mat-select formControlName="maxDepositTermTypeId">
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <h4 fxFlex="23%" class="mat-h4">{{'labels.heading.For Pre-mature closure' | translate}} <i class='fas fa-question-circle' matTooltip="{{ 'tooltips.This sections defines an interest penalty' | translate}}"></i></h4>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="preClosurePenalApplicable" class="margin-v">
      {{'labels.inputs.Apply Penal Interest (less)' | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Penal Interest' | translate}} (%)</mat-label>
      <input type="number" matInput formControlName="preClosurePenalInterest">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.Period' | translate}}</mat-label>
      <mat-select formControlName="preClosurePenalInterestOnTypeId">
        <mat-option *ngFor="let preClosurePenalInterestOnType of preClosurePenalInterestOnTypeData" [value]="preClosurePenalInterestOnType.id">
          {{ preClosurePenalInterestOnType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="withHoldTax" class="margin-v" matTooltip="{{ 'tooltips.An boolean flag to attach' | translate}}">
      {{'labels.inputs.Is Withhold Tax Applicable' | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%" *ngIf="fixedDepositProductSettingsForm.value.withHoldTax">
      <mat-label>{{'labels.inputs.Tax Group' | translate}}</mat-label>
      <mat-select formControlName="taxGroupId" required>
        <mat-option *ngFor="let taxGroup of taxGroupData" [value]="taxGroup.id">
          {{ taxGroup.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{'labels.inputs.Tax Group' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{'labels.buttons.Previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{'labels.buttons.Next' | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
