<div class="container">

  <mat-card>

    <form [formGroup]="frequentPostingsForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.officeId.hasError('required')">
              {{"labels.inputs.Office" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Accounting Rule" | translate}}</mat-label>
            <mat-select required formControlName="accountingRule">
              <mat-option *ngFor="let accountingRule of accountingRuleData" [value]="accountingRule">
                {{ accountingRule.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.accountingRule.hasError('required')">
              {{"labels.inputs.Accounting Rule" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Currency" | translate}}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.currencyCode.hasError('required')">
              {{"labels.inputs.Currency" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <span fxFlex="48%" fxHide.lt-md></span>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="debits"
            *ngFor="let debit of debits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>{{"labels.inputs.Affected GL Entry (Debit)" | translate}}</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryDebit of debitAccountData" [value]="affectedGLEntryDebit.id">
                    {{ affectedGLEntryDebit.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="debits.at(i).controls.glAccountId.hasError('required')">
                  {{"labels.inputs.Affected GL Entry (Debit)" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>{{"labels.inputs.Debit Amount" | translate}}</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="debits.at(i).controls.amount.hasError('required')">
                  {{"labels.inputs.Debit Amount" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(debits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0 && allowMultipleDebitEntries" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(debits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="credits"
            *ngFor="let credit of credits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>{{"labels.inputs.Affected GL Entry (Credit)" | translate}}</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryCredit of creditAccountData" [value]="affectedGLEntryCredit.id">
                    {{ affectedGLEntryCredit.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="credits.at(i).controls.glAccountId.hasError('required')">
                  {{"labels.inputs.Affected GL Entry (Credit)" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>{{"labels.inputs.Credit Amount"|translate}}</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="credits.at(i).controls.amount.hasError('required')">
                  {{"labels.inputs.Credit Amount"|translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(credits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0 && allowMultipleCreditEntries" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(credits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Reference Number"|translate}}</mat-label>
            <input matInput formControlName="referenceNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="transactionDatePicker.open()">
            <mat-label>{{"labels.inputs.Transaction Date"|translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transactionDatePicker" required formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transactionDatePicker></mat-datepicker>
            <mat-error *ngIf="frequentPostingsForm.controls.transactionDate.hasError('required')">
              {{"labels.inputs.Transaction Date"|translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Payment Type"|translate}}</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of paymentTypeData" [value]="paymentType.id">
                {{ paymentType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Account Number"|translate}}</mat-label>
            <input matInput formControlName="accountNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Cheque Number"|translate}}</mat-label>
            <input matInput formControlName="checkNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Routing Code"|translate}}</mat-label>
            <input matInput formControlName="routingCode">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Receipt Number"|translate}}</mat-label>
            <input matInput formControlName="receiptNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.Bank Number"|translate}}</mat-label>
            <input matInput formControlName="bankNumber">
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{"labels.inputs.Comments"|translate}}</mat-label>
            <textarea matInput formControlName="comments" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!frequentPostingsForm.valid" *mifosxHasPermission="'CREATE_JOURNALENTRY'">{{"labels.buttons.Submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
