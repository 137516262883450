<div class="container">

    <form [formGroup]="surveyForm" (ngSubmit)="submit()">

      <div fxLayout="column" fxLayoutGap="4%">

        <mat-card>

          <mat-card-content>

            <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

              <mat-form-field fxFlex="48%">
                <mat-label>{{'labels.inputs.Key' | translate}}</mat-label>
                <input matInput maxlength="32" required formControlName="key">
                <mat-error *ngIf="surveyForm.controls.key.hasError('required')">
                  {{'labels.inputs.Key' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' |translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="48%">
                <mat-label>{{'labels.inputs.Name' | translate}}</mat-label>
                <input matInput maxlength="255" required formControlName="name">
                <mat-error *ngIf="surveyForm.controls.name.hasError('required')">
                  {{'labels.inputs.Name' | translate}}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="48%">
                <mat-label>{{ 'labels.inputs.Country Code' | translate }} </mat-label>
                <input matInput maxlength="2" required formControlName="countryCode">
                <mat-error *ngIf="surveyForm.controls.countryCode.hasError('required')">
                  {{ 'labels.inputs.Country Code' | translate }}   {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                </mat-error>
                <mat-error *ngIf="surveyForm.controls.countryCode.hasError('pattern')">
                  {{ 'labels.inputs.Country Code' | translate }} <strong>{{ 'labels.commons.must consist of 2 alphabetic characters' | translate }}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="98%">
                <mat-label>{{ 'labels.inputs.Description' | translate }}</mat-label>
                <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
              </mat-form-field>

            </div>

          </mat-card-content>

        </mat-card>

        <div cdkDropList (cdkDropListDropped)="dropQuestion($event)" fxLayout="column" fxLayoutGap="4%">

          <mat-card cdkDrag cdkDragLockAxis="y" formArrayName="questionDatas" *ngFor="let question of questionDatas.controls; let questionIndex = index; last as isLast">

            <mat-card-content>

              <div fxFlexFill fxLayoutGap="2%" fxLayout.lt-md="column" fxLayout="row wrap" [formGroupName]="questionIndex">

                <div fxFlex="98%" fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="space-between center">
                  <h2 class="mat-h2">{{'labels.heading.Question' | translate}} {{ questionIndex + 1 }}</h2>
                  <button mat-raised-button color="warn" (click)="removeQuestion(questionIndex)" [disabled]="questionDatas.controls.length === 1">
                    <fa-icon icon="trash" class="m-r-10"></fa-icon>
                    {{'labels.buttons.Delete' | translate }} {{'labels.heading.Question' | translate}}
                  </button>
                </div>

                <mat-form-field fxFlex="48%">
                  <mat-label>{{'labels.inputs.Key' | translate}}</mat-label>
                  <input matInput maxlength="32" required formControlName="key">
                  <mat-error *ngIf="question.get('key').hasError('required')">
                    {{'labels.inputs.Key' | translate}}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="48">
                  <mat-label>{{'labels.inputs.Text' | translate}}</mat-label>
                  <input matInput maxlength="255" required formControlName="text">
                  <mat-error *ngIf="question.get('text').hasError('required')">
                    {{'labels.inputs.Text' | translate}}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="98%">
                  <mat-label>{{ 'labels.inputs.Description' | translate }}</mat-label>
                  <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
                </mat-form-field>

                <mat-divider [inset]="true"></mat-divider>

                <div fxFlex="98%" fxLayout="row" fxLayoutAlign="space-between center">
                  <h4 class="mat-h4">{{'labels.heading.Options' | translate}}</h4>
                  <button type="button" mat-raised-button color="primary" (click)="addResponse(questionIndex)">
                    <fa-icon icon="plus" class="m-r-10"></fa-icon>
                    {{'labels.buttons.Add' | translate}} {{'labels.inputs.Option' | translate}}
                  </button>
                </div>

                <div cdkDropList (cdkDropListDropped)="dropResponse($event, questionIndex)" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%">

                  <div cdkDrag cdkDragLockAxis="y" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" formArrayName="responseDatas" *ngFor="let response of getResponseDatas(questionIndex).controls; let responseIndex = index;">

                    <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" [formGroupName]="responseIndex">

                      <mat-form-field fxFlex="43%">
                        <mat-label>{{'labels.inputs.Text' | translate}}</mat-label>
                        <input matInput required formControlName="text">
                        <mat-error *ngIf="response.get('text').hasError('required')">
                          {{'labels.inputs.Text' | translate}}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="43%">
                        <mat-label>{{'labels.inputs.Value' | translate}}</mat-label>
                        <input matInput required formControlName="value">
                        <mat-error *ngIf="response.get('value').hasError('required')">
                          {{'labels.inputs.Value' | translate}}  {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
                        </mat-error>
                        <mat-error *ngIf="response.get('value').hasError('pattern')">
                          {{'labels.inputs.Value' | translate}}<strong>{{'labels.commons.must be an integer between -9999 and 9999' | translate}}</strong>
                        </mat-error>
                      </mat-form-field>

                      <div fxFlex="8%">
                        <div class="delete-wrapper">
                          <button type="button" color="warn" mat-icon-button  [matTooltip]="('labels.buttons.Delete' | translate)" matTooltipPosition="above" (click)="removeResponse(getResponseDatas(questionIndex), responseIndex)" [disabled]="getResponseDatas(questionIndex).controls.length === 1">
                            <fa-icon icon="trash" size="lg"></fa-icon>
                          </button>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </mat-card-content>

            <div>

              <mat-card-actions *ngIf="isLast" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <button type="button" mat-raised-button (click)="cancelSurvey()">{{'labels.buttons.Cancel' | translate}}</button>
                <button type="button" mat-raised-button color="primary" (click)="addQuestion()">
                  <fa-icon icon="plus" class="m-r-10"></fa-icon>
                  {{'labels.buttons.Add' | translate}} {{'labels.heading.Question' | translate}}
                </button>
                <button mat-raised-button color="primary" [disabled]="!surveyForm.valid">{{'labels.text.Edit Survey' | translate}}</button>
              </mat-card-actions>

            </div>

          </mat-card>

        </div>

      </div>

    </form>

  </div>
