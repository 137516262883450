<div class="container">

    <mat-card>

        <form [formGroup]="clientCollateralForm" (ngSubmit)="submit()">

            <mat-card-content>

                <div fxLayout="column">
                    <mat-form-field>
                        <mat-label>{{"labels.inputs.Collateral" | translate}}</mat-label>
                        <mat-select required formControlName="collateralId">
                            <mat-option *ngFor="let clientCollateral of clientCollateralOptions" [value]="clientCollateral.id">
                                {{ clientCollateral.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="clientCollateralForm.controls.collateralId.hasError('required')">
                            {{"labels.inputs.Collateral" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <div *ngIf="collateralDetails" fxLayout="column">

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
                            <input matInput required formControlName="name">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Type/Quality" | translate}}</mat-label>
                            <input matInput required formControlName="quality">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Unit Type" | translate}}</mat-label>
                            <input matInput required formControlName="unitType">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Base Price" | translate}}</mat-label>
                            <input matInput required formControlName="basePrice">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Percentage To Base" | translate}}</mat-label>
                            <input matInput required formControlName="pctToBase">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Quantity" | translate}}</mat-label>
                            <input matInput required formControlName="quantity">
                            <mat-error *ngIf="clientCollateralForm.controls.quantity.hasError('required')">
                                {{"labels.inputs.Quantity" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Total" | translate}}</mat-label>
                            <input matInput required formControlName="totalValue">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{"labels.inputs.Total Collateral Value" | translate}}</mat-label>
                            <input matInput required formControlName="totalCollateralValue">
                        </mat-form-field>

                    </div>

                </div>

            </mat-card-content>

            <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
                <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel" | translate}}</button>
                <button mat-raised-button color="primary" [disabled]="!clientCollateralForm.valid">{{"labels.buttons.Submit" | translate}}</button>
            </mat-card-actions>

        </form>

    </mat-card>

</div>
