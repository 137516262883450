<div class="add-family-member-container">

  <form [formGroup]="addFamilyMemberForm" (ngSubmit)="submit()">

    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center">

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.First Name" | translate}}</mat-label>
        <input formControlName="firstName" required matInput />
        <mat-error *ngIf="addFamilyMemberForm.controls.firstName.hasError('required')">
          {{"labels.inputs.First Name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Middle Name" | translate}}</mat-label>
        <input formControlName="middleName" matInput />
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Last Name" | translate}}</mat-label>
        <input formControlName="lastName" required matInput />
        <mat-error *ngIf="addFamilyMemberForm.controls.lastName.hasError('required')">
          {{"labels.inputs.Last Name" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Qualification" | translate}}</mat-label>
        <input formControlName="qualification" matInput />
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Age" | translate}}</mat-label>
        <input type="number" formControlName="age" required matInput [min]="minAge" />
        <mat-error *ngIf="addFamilyMemberForm.controls.age.hasError('required')">
          {{"labels.inputs.Age" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required and cannot be negative" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="isDependent" labelPosition="before" fxFlex="calc(50% - 25px)">{{"labels.inputs.Is Dependent" | translate}}?
      </mat-checkbox>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Relationship" | translate}}</mat-label>
        <mat-select formControlName="relationshipId" required>
          <mat-option *ngFor="let relation of addFamilyMemberTemplate.relationshipIdOptions" [value]="relation.id">
            {{ relation.name | translateKey:'catalogs' }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addFamilyMemberForm.controls.relationshipId.hasError('required')">
          {{"labels.inputs.Relationship" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Gender" | translate}}</mat-label>
        <mat-select formControlName="genderId" required>
          <mat-option *ngFor="let gender of addFamilyMemberTemplate.genderIdOptions" [value]="gender.id">
            {{ gender.name | translateKey:'catalogs' }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addFamilyMemberForm.controls.genderId.hasError('required')">
          {{"labels.inputs.Gender" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Profession" | translate}}</mat-label>
        <mat-select formControlName="professionId">
          <mat-option *ngFor="let profession of addFamilyMemberTemplate.professionIdOptions" [value]="profession.id">
            {{ profession.name | translateKey:'catalogs' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{"labels.inputs.Marital Status" | translate}}</mat-label>
        <mat-select formControlName="maritalStatusId">
          <mat-option *ngFor="let maritalStatus of addFamilyMemberTemplate.maritalStatusIdOptions"
            [value]="maritalStatus.id">
            {{ maritalStatus.name | translateKey:'catalogs'}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field fxFlex (click)="dueDatePicker.open()">
        <mat-label>{{"labels.inputs.Date Of Birth" | translate}}</mat-label>
        <input formControlName="dateOfBirth" matInput [max]="maxDate" [matDatepicker]="dueDatePicker"
          required>
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
        <mat-error *ngIf="addFamilyMemberForm.controls.dateOfBirth.hasError('required')">
          {{"labels.inputs.Date Of Birth" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="center">
      <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.Cancel" | translate}}</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!addFamilyMemberForm.valid">{{"labels.buttons.Submit" | translate}}</button>
    </div>

  </form>

</div>
