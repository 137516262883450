<div class="container">

  <mat-card fxLayout="column" fxLayoutGap="3%">

    <mat-form-field fxFlex>
      <mat-label>{{"labels.inputs.Meeting Date"  |translate}}</mat-label>
      <mat-select [formControl]="meetingDate">
        <mat-option *ngFor="let date of meetingDates" [value]="date">
          {{ date | dateFormat }}
        </mat-option>
      </mat-select>
      <mat-hint>{{"labels.inputs.Next Meeting on"  |translate}}: {{this.groupData.collectionMeetingCalendar.nextTenRecurringDates[0] | dateFormat}}</mat-hint>
    </mat-form-field>

    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.name"  |translate}} </th>
        <td mat-cell *matCellDef="let member"> {{ member.clientId | find:membersData:'id':'displayName' }} </td>
      </ng-container>

      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Attendance"  |translate}} </th>
        <td mat-cell *matCellDef="let member" [ngSwitch]="member.attendanceType">
          <span *ngSwitchCase="1">{{"labels.inputs.Present"  |translate}}</span>
          <span *ngSwitchCase="2">{{"labels.inputs.Absent"  |translate}}</span>
          <span *ngSwitchCase="3">{{"labels.inputs.Approved"  |translate}}</span>
          <span *ngSwitchCase="4">{{"labels.inputs.Leave"  |translate}}</span>
          <span *ngSwitchCase="5">{{"labels.inputs.Late"  |translate}}</span>
          <button mat-icon-button color="primary"
            (click)="editAttendance(member)">
            <fa-icon icon="pen"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.Cancel"  |translate}}</button>
      <button mat-raised-button color="primary" (click)="submit()"
        *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'">{{"labels.buttons.Confirm"  |translate}}</button>
    </mat-card-actions>

  </mat-card>

</div>
