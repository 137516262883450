<div class="m-b-20" fxLayoutAlign="end">
  <mat-button-toggle-group  aria-label="Select Chart Type">
    <mat-button-toggle value="Bar" (click) = "setBarChart(inputData)">{{"labels.buttons.Bar Chart" | translate}}</mat-button-toggle>
    <mat-button-toggle value="Pie" (click) = "setPieChart(inputData)">{{"labels.buttons.Pie Chart" | translate}}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div [ngStyle]="{'display': (hideOutput) ? 'none' : 'block'}">
  <canvas id="output"></canvas>
</div>
