<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content [formGroup]="depositProductIncentiveForm" fxLayout="column">

  <mat-form-field>
    <mat-label>{{'labels.inputs.Attribute' | translate}}</mat-label>
    <mat-select formControlName="attributeName" required>
      <mat-option *ngFor="let attributeName of attributeNameData" [value]="attributeName.id">
        {{ attributeName.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{'labels.inputs.Attribute' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'labels.inputs.Condition' | translate}}</mat-label>
    <mat-select formControlName="conditionType" required>
      <mat-option *ngFor="let conditionType of conditionTypeData" [value]="conditionType.id">
        {{ conditionType.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{'labels.inputs.Condition' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="depositProductIncentiveForm.value.attributeName">
    <mat-label>{{'labels.inputs.Value' | translate}}</mat-label>
    <mat-select formControlName="attributeValue" *ngIf="depositProductIncentiveForm.value.attributeName !== 3" required>
      <mat-option *ngFor="let attributeValue of attributeValueData" [value]="attributeValue.id">
        {{ attributeValue.name }}
      </mat-option>
    </mat-select>
    <input type="number" matInput formControlName="attributeValue" *ngIf="depositProductIncentiveForm.value.attributeName === 3" required>
    <mat-error>
      {{'labels.inputs.Value' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'labels.inputs.Type' | translate}}</mat-label>
    <mat-select formControlName="incentiveType" required>
      <mat-option *ngFor="let incentiveType of incentiveTypeData" [value]="incentiveType.id">
        {{ incentiveType.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{'labels.inputs.Type' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'labels.inputs.Interest' | translate}}</mat-label>
    <input type="number" matInput formControlName="amount" required>
    <mat-error>
      {{'labels.inputs.Interest' | translate}} {{'labels.commons.is' | translate}} <strong>{{'labels.commons.required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

</div>

<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button mat-dialog-close> {{'labels.buttons.Cancel' | translate}} </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{ data: depositProductIncentiveForm }" [disabled]="!depositProductIncentiveForm.valid || depositProductIncentiveForm.pristine">
    {{'labels.buttons.' + layout.addButtonText | translate }}
  </button>
</mat-dialog-actions>
