<div class="container m-b-20" fxLayoutAlign="end">
  <button mat-raised-button color="primary" [routerLink]="['../Take Survey']">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{"labels.buttons.Take Survey" | translate}}
  </button>
</div>


<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="surveyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="surveyName"> {{"labels.inputs.Survey" | translate}} </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.surveyName }} </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy"> {{"labels.inputs.Created By" | translate}} </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.createdBy }} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.DateTime" | translate}} </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.date  | datetimeFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.Score" | translate}} </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.score | formatNumber }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
