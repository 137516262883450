<div class="container">

  <mat-card>

    <form [formGroup]="smsConfigurationForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{'labels.inputs.Host Name' | translate}}</mat-label>
            <input matInput required formControlName="host_name">
            <mat-error *ngIf="smsConfigurationForm.controls.host_name.hasError('required')">
              {{'labels.inputs.Host Name' | translate}} {{ "labels.commons.is" | translate}} <strong>{{ "labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.Port Number' | translate}}</mat-label>
            <input matInput required formControlName="port_number">
            <mat-error *ngIf="smsConfigurationForm.controls.port_number.hasError('required')">
              {{'labels.inputs.Port Number' | translate}} {{ "labels.commons.is" | translate}} <strong>{{ "labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.End Point' | translate}}</mat-label>
            <input matInput required formControlName="end_point">
            <mat-error *ngIf="smsConfigurationForm.controls.end_point.hasError('required')">
              {{'labels.inputs.End Point' | translate}} {{ "labels.commons.is" | translate}} <strong>{{ "labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
              <mat-label>{{'labels.inputs.Tenant App Key' | translate}}</mat-label>
              <input matInput required formControlName="tenant_app_key">
              <mat-error *ngIf="smsConfigurationForm.controls.tenant_app_key.hasError('required')">
                {{'labels.inputs.Tenant App Key' | translate}} {{ "labels.commons.is" | translate}} <strong>{{ "labels.commons.required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!smsConfigurationForm.valid || smsConfigurationForm.pristine">{{'labels.buttons.Submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
