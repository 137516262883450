<form [formGroup]="shareProductAccountingForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-radio-group fxFlex="98%" fxLayout="row" fxLayoutGap="5%" fxLayout.lt-md="column" formControlName="accountingRule">
      <mat-radio-button *ngFor="let accountingRule of accountingRuleData; let i =  index" [value]="i+1">
        {{ 'labels.accounting.' + accountingRule | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-divider fxFlex="98%"></mat-divider>

    <div *ngIf="shareProductAccountingForm.value.accountingRule === 2" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">{{'labels.heading.Assets' | translate}}</h4>

      <mifosx-gl-account-selector fxFlex="48%"
        [inputFormControl]="shareProductAccountingForm.controls.shareReferenceId"
        [glAccountList]="assetAccountData" [required]="true" [inputLabel]="'Share reference'">
      </mifosx-gl-account-selector>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{'labels.heading.Liabilities' | translate}}</h4>

      <mifosx-gl-account-selector fxFlex="48%"
        [inputFormControl]="shareProductAccountingForm.controls.shareSuspenseId"
        [glAccountList]="liabilityAccountData" [required]="true" [inputLabel]="'Share suspense control'">
      </mifosx-gl-account-selector>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{'labels.heading.Share Equity' | translate}}</h4>

      <mifosx-gl-account-selector fxFlex="48%"
        [inputFormControl]="shareProductAccountingForm.controls.shareEquityId"
        [glAccountList]="equityAccountData" [required]="true" [inputLabel]="'Equity'">
      </mifosx-gl-account-selector>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{'labels.heading.Income' | translate}}</h4>

      <mifosx-gl-account-selector fxFlex="48%"
        [inputFormControl]="shareProductAccountingForm.controls.incomeFromFeeAccountId"
        [glAccountList]="incomeAccountData" [required]="true" [inputLabel]="'Income from fees'">
      </mifosx-gl-account-selector>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{'labels.buttons.Previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext [disabled]="!shareProductFormValid">
      {{'labels.buttons.Next' | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</form>
