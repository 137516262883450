<!-- TODO: Refactor Permissions -->
<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Offices']">
              <fa-icon icon="building" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Offices']">{{'labels.heading.Offices' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="!arrowBooleans[0]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(0)" *ngIf="arrowBooleans[0]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[0]" [routerLink]="['Offices']">{{ 'labels.text.Download, Upload Offices Template' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Users']">
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Users']">{{'labels.heading.Users' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="!arrowBooleans[1]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(1)" *ngIf="arrowBooleans[1]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[1]" [routerLink]="['Users']">{{ 'labels.text.Download offices template and Upload office excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Groups']">
              <fa-icon icon="users" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Groups']">{{'labels.heading.Groups' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="!arrowBooleans[2]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(2)" *ngIf="arrowBooleans[2]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[2]" [routerLink]="['Groups']">{{ 'labels.text.Download groups template and Upload group excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Loan Accounts']">
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Loan Accounts']">{{'labels.heading.Loan Accounts' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="!arrowBooleans[3]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(3)" *ngIf="arrowBooleans[3]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[3]" [routerLink]="['Loan Accounts']">{{ 'labels.text.Download loan accounts template and upload loan account excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Savings Accounts']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Savings Accounts']">{{'labels.heading.Savings Accounts' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="!arrowBooleans[4]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(4)" *ngIf="arrowBooleans[4]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[4]" [routerLink]="['Savings Accounts']">{{ 'labels.text.Download savings accounts template and upload savings account excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Fixed Deposit Accounts']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Fixed Deposit Accounts']">{{'labels.heading.Fixed Deposit Accounts' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="!arrowBooleans[5]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(5)" *ngIf="arrowBooleans[5]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[5]" [routerLink]="['Fixed Deposit Accounts']">{{ 'labels.text.Download fixed deposit accounts template and upload fixed deposit account excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Chart of Accounts']">
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Chart of Accounts']">{{'labels.heading.Chart of Accounts' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="!arrowBooleans[6]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(6)" *ngIf="arrowBooleans[6]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[6]" [routerLink]="['Chart of Accounts']">{{ 'labels.text.Download chart of accounts template and upload chart of account excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Share Accounts']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Share Accounts']">{{'labels.heading.Share Accounts' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="!arrowBooleans[7]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(7)" *ngIf="arrowBooleans[7]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[7]" [routerLink]="['Share Accounts']">{{ 'labels.text.Download share accounts template and upload share account excel files' | translate }}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Employees']">
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Employees']">{{'labels.heading.Employees' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="!arrowBooleans[8]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(8)" *ngIf="arrowBooleans[8]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[8]" [routerLink]="['Employees']">{{ 'labels.text.Download loan accounts template and upload loan account excel files' | translate }}</p>
          </mat-list-item>


          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Clients']">
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Clients']">{{'labels.heading.Clients' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="!arrowBooleans[9]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(9)" *ngIf="arrowBooleans[9]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[9]" [routerLink]="['Clients']">{{ 'labels.text.Download clients template and upload clients excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Centers']">
              <fa-icon icon="users" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Centers']">{{'labels.heading.Centers' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="!arrowBooleans[10]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(10)" *ngIf="arrowBooleans[10]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[10]" [routerLink]="['Centers']">{{ 'labels.text.Download centers template and upload centers excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Loan Repayments']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Loan Repayments']">{{'labels.heading.Loan Repayments' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(11)" *ngIf="!arrowBooleans[11]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(11)" *ngIf="arrowBooleans[11]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[11]" [routerLink]="['Loan Repayments']">{{ 'labels.text.Download loan repayments template and upload loan repayment excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Savings Transactions']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Savings Transactions']">{{'labels.heading.Savings Transactions' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(12)" *ngIf="!arrowBooleans[12]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(12)" *ngIf="arrowBooleans[12]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[12]" [routerLink]="['Savings Transactions']">{{ 'labels.text.Download savings transactions template and upload savings transaction excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Fixed Deposit Transactions']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Fixed Deposit Transactions']">{{'labels.heading.Fixed Deposit Transactions' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(13)" *ngIf="!arrowBooleans[13]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(13)" *ngIf="arrowBooleans[13]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[13]" [routerLink]="['Fixed Deposit Transactions']">{{ 'labels.text.Download fixed deposit transactions template and upload fixed deposit transaction excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Recurring Deposit Transactions']">
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Recurring Deposit Transactions']">{{'labels.heading.Recurring Deposit Transactions' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(14)" *ngIf="!arrowBooleans[14]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(14)" *ngIf="arrowBooleans[14]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[14]" [routerLink]="['Recurring Deposit Transactions']">{{ 'labels.text.Download recurring deposit transactions template and upload recurring deposit transaction excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Journal Entries']">
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Journal Entries']">{{'labels.heading.Journal Entries' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(15)" *ngIf="!arrowBooleans[15]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(15)" *ngIf="arrowBooleans[15]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[15]" [routerLink]="['Journal Entries']">{{ 'labels.text.Download journal entries template and upload journal entries excel files' | translate }}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon [routerLink]="['Guarantors']">
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine [routerLink]="['Guarantors']">{{'labels.heading.Guarantors' | translate }}</h4>
            <fa-icon (click)="arrowBooleansToggle(16)" *ngIf="!arrowBooleans[16]" icon="arrow-down" size="md"></fa-icon>
            <fa-icon (click)="arrowBooleansToggle(16)" *ngIf="arrowBooleans[16]" icon="arrow-up" size="md"></fa-icon>
            <p matLine *ngIf="arrowBooleans[16]" [routerLink]="['Guarantors']">{{ 'labels.text.Download guarantors template and upload guarantor excel files' | translate }}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
