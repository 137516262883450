<div class="container m-b-20 space-top" fxLayout="row" fxLayoutGap="20px">
  <div #schedulerStatus>
    <h2 class="no-m">{{'labels.heading.Scheduler Status' | translate}}:<span class="m-l-20 m-r-20">{{ schedulerActive ? ( 'labels.inputs.Active' | translate ) : ('labels.catalogs.Inactive' | translate) }}</span>
    </h2>
  </div>
  <button mat-raised-button class="suspend" (click)="suspendScheduler()" *ngIf="schedulerActive">
    <fa-icon icon="times-circle" class="m-r-10"></fa-icon>
    {{'labels.buttons.Suspend' | translate}}
  </button>
  <button mat-raised-button class="activate" (click)="activateScheduler()" *ngIf="!schedulerActive">
    <fa-icon icon="times-circle" class="m-r-10"></fa-icon>
    {{'labels.buttons.Activate' | translate}}
  </button>

</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="space-top" fxLayout="row" fxLayoutGap="20px">
    <button mat-raised-button color="primary" (click)="runSelectedJobs()" [disabled]="!isAnyJobSelected()">
      <fa-icon icon="play" class="m-r-10"></fa-icon>
      {{'labels.buttons.Run Selected Jobs' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="openCustomParametersDialog()" [disabled]="!isAnyJobSelected()">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      {{'labels.buttons.Add Custom Parameters' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="refresh()">
      <fa-icon icon="sync" class="m-r-10"></fa-icon>
      {{'labels.buttons.Refresh' | translate}}
    </button>
  </div>

  <div #jobsTable class="mat-elevation-z8 space-top">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox class="m-r-10" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox class="m-r-10" *ngIf="!row.currentlyRunning && row.active" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Name' | translate}} </th>
        <td mat-cell *matCellDef="let job" [routerLink]="[job.jobId]"> {{ job.displayName }} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Active' | translate}}</th>
        <td mat-cell *matCellDef="let job" class="center">
          <div [className]="job.active === true ? 'currently-running' : 'not-currently-running'">
            <fa-icon matTooltip="{{ job.active === true | yesNo }}" matTooltipPosition="right" icon="circle"
              size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="previousRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Previous Run' | translate}} </th>
        <td mat-cell *matCellDef="let job">
          <div *ngIf="job.lastRunHistory">
            <fa-icon *ngIf="job.lastRunHistory.status === 'success'" class="success" [matTooltip]="('tooltips.Successful' | translate)"
              matTooltipPosition="right" icon="check-circle" size="lg"></fa-icon>
            <fa-icon *ngIf="!(job.lastRunHistory.status === 'success')" class="fail" [matTooltip]="('tooltips.Failed' | translate)"
              matTooltipPosition="right" icon="times-circle" size="lg"></fa-icon>
          <span *ngIf="job.lastRunHistory" class="m-l-5">
            {{ job.lastRunHistory.jobRunStartTime | datetimeFormat}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nextRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Next Run' | translate}} </th>
        <td mat-cell *matCellDef="let job"> {{ job.nextRunTime | datetimeFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="currentlyRunning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Currently Running' | translate}} </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div [className]="job.currentlyRunning === true ? 'currently-running' : 'not-currently-running'">
            <fa-icon matTooltip="{{ job.currentlyRunning === true | yesNo }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Error Log' | translate}} </th>
        <td mat-cell *matCellDef="let job" class="center">
          <button mat-icon-button class="errorlog" (click)="showErrorLog(job)"
            *ngIf="!(job.lastRunHistory && job.lastRunHistory.status === 'success')" matTooltip="{{'labels.inputs.Error Log' | translate}}"
            matTooltipPosition="right">
            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<div class="container space-top" fxLayout="row" fxLayoutGap="20px">
  <button mat-raised-button color="primary" (click)="runSelectedJobs()" [disabled]="!isAnyJobSelected()">
    <fa-icon icon="play" class="m-r-10"></fa-icon>
    {{'labels.buttons.Run Selected Jobs' | translate}}<ng-template #templateSchedulerJobs let-data let-popover="popover">
      <h2>{{'labels.heading.Template' | translate}}</h2>
      <p>{{'labels.text.Template I am trying to use' | translate}}.</p>
      <button (click)="popover.close();nextStepSchedulerJobs()">{{'labels.buttons.Next' | translate }}</button>
    </ng-template>
  </button>
  <button mat-raised-button color="primary" (click)="refresh()">
    <fa-icon icon="sync" class="m-r-10"></fa-icon>
    {{'labels.buttons.Refresh' | translate}}
  </button>
</div>

<ng-template #templateSchedulerStatus let-data let-popover="popover">
  <h4>{{'labels.heading.Scheduler status' | translate}}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.Next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-data let-popover="popover">
  <h4>{{'labels.heading.Search bar to filter jobs' | translate }}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateSchedulerStatus,schedulerStatus, 'bottom', true)">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateJobsTable,jobsTable, 'top', true)">{{'labels.buttons.Next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateJobsTable let-data let-popover="popover">
  <h4>{{'labels.heading.List of all scheduled batch jobs' | translate }} <a
      href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895356/Manage+Scheduler+Jobs" target="_blank">{{'labels.heading.Schedular Jobs' | translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.Back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.Next' | translate}}</button>
  </div>
</ng-template>
