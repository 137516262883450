<h1 mat-dialog-title>{{ 'labels.heading.Change Password' | translate }}</h1>

<div mat-dialog-content>

  <form [formGroup]="changePasswordForm">

    <div fxLayout="column">

      <mat-form-field>
        <mat-label>{{ 'labels.inputs.Password' | translate }}</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('required')">
          {{ 'labels.inputs.Password' | translate }} <strong>{{'labels.commons.is required' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('minlength')">
          {{'labels.commons.Password should be at least' | translate }} <strong>{{'labels.commons.8 characters long' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('maxlength')">
          {{'labels.commons.Password should not be more than' | translate }} <strong>{{'labels.commons.50 characters long' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('pattern') && !(changePasswordForm.controls.password.hasError('minlength') || changePasswordForm.controls.password.hasError('maxlength'))">
          {{'labels.commons.Password should include a' | translate }} <strong>{{'labels.commons.numeral' | translate }}</strong> {{'labels.commons.and at' | translate }} <strong>{{'labels.commons.least one uppercase' | translate }}</strong> ,<strong>{{'labels.commons.lowercase and special character' | translate }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'labels.inputs.Repeat Password' | translate }}</mat-label>
        <input matInput formControlName="repeatPassword" type="password">
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('required')">
          {{ 'labels.inputs.Password' | translate }} <strong>{{'labels.commons.required' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('notequal')">
          {{ 'labels.inputs.Password' | translate }} <strong>{{'labels.commons.does not match' | translate }}</strong>
        </mat-error>
      </mat-form-field>

    </div>

  </form>

</div>

<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>{{ 'labels.buttons.Cancel' | translate }}</button>
  <button mat-raised-button color="warn" [disabled]="!changePasswordForm.valid" [mat-dialog-close]="{password:changePasswordForm.get('password').value,repeatPassword:changePasswordForm.get('repeatPassword').value}">{{ 'labels.buttons.Confirm' | translate }}</button>
</mat-dialog-actions>
