<h1 mat-dialog-title>{{"labels.heading.Welcome to Mifos" | translate}}</h1>

<mat-dialog-content>

<h3 class="mw600">{{"labels.heading.Configure Mifos Organization Needs" | translate}}</h3>


<mat-grid-list cols="3" rowHeight="50px">

      <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 1 }">{{"labels.buttons.Home Screen Tour" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 2 }">{{"labels.buttons.Setup Organization" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 3 }">{{"labels.buttons.Setup System" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 4 }">{{"labels.buttons.Setup Accounting" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 5 }">{{"labels.buttons.Setup Products" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 6 }">{{"labels.buttons.Setup Funds and Reports" | translate}}</button>
	  </mat-grid-tile>

</mat-grid-list>

<h3>{{"labels.heading.Progress Bar" | translate}} : 0%</h3>

<mat-progress-bar mode="determinate" value="0"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="{ show: 0 }">{{"labels.buttons.Close Config. Wizard" | translate}}</button>
</mat-dialog-actions>
