<div class="tab-container mat-typography">
  <div *ngIf="centerViewData.status.value=='Closed'">
    <h3 class="closedCenter">{{"labels.heading.Center Closed" | translate}}</h3>
  </div>
  <h3>{{"labels.heading.Summary Details" | translate}}</h3>
  <div fxLayout="row" fxLayoutGap="32px" class="summary-details-container">
    <p>
      {{"labels.inputs.Active Clients" | translate}}: {{centerSummaryData.activeClients}}<br />
      {{"labels.inputs.Active Group Loans" | translate}}: {{centerSummaryData.activeGroupLoans}}<br />
      {{"labels.inputs.Active Client Loans" | translate}}: {{centerSummaryData.activeClientLoans}}<br />
      {{"labels.inputs.Active Overdue Group Loans" | translate}}: {{centerSummaryData.overdueGroupLoans}}<br />
    </p>
    <p>
      {{"labels.inputs.Active Group Borrowers" | translate}}: {{centerSummaryData.activeGroupBorrowers}}<br />
      {{"labels.inputs.Active Client Borrowers" | translate}}: {{centerSummaryData.activeClientBorrowers}}<br />
      {{"labels.inputs.Active Overdue Client Loans" | translate}}: {{centerSummaryData.overdueClientLoans}}<br />
    </p>
  </div>

  <!-- Groups -->
  <ng-container *ngIf="groupResourceData.length > 0">

    <h3>{{"labels.heading.Groups"|translate}}</h3>

    <table mat-table [dataSource]="groupResourceData" matSort>

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account Number" | translate}} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
            [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Group Name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Group Name" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="Office Name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Office Name" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.officeName}} </td>
      </ng-container>

      <ng-container matColumnDef="Submitted On">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Submitted On" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.timeline.submittedOnDate | dateFormat}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="groupsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: groupsColumns;" [routerLink]="['/groups', row.id, 'general']"></tr>

    </table>

  </ng-container>

  <!-- savings overview table -->
  <ng-container *ngIf="!(savingsAccountData === undefined)">

    <ng-container *ngIf="savingsAccountData.length > 0">

      <h3>{{"labels.heading.Savings Account Overview" | translate}}</h3>

      <table mat-table [dataSource]="savingsAccountData" matSort>

        <ng-container matColumnDef="Account No">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Account No" | translate}} </th>
          <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
              [ngClass]="element.status.code|statusLookup"></i>
            {{element.accountNo}} </td>
        </ng-container>

        <ng-container matColumnDef="Products">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Products" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
        </ng-container>

        <ng-container matColumnDef="Balance">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Balance" | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.Actions" | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status.active">
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" matTooltip="{{ 'tooltips.Deposit' | translate}}"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" matTooltip="{{ 'tooltips.Deposit' | translate}}"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" matTooltip="{{ 'tooltips.Withdraw' | translate}}"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" matTooltip="{{ 'tooltips.Withdraw' | translate}}"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="element.status.submittedAndPendingApproval">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Approve']">
                <i class="fa fa-check" matTooltip="{{ 'tooltips.Approve' | translate}}"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="!element.status.submittedAndPendingApproval && !element.status.active">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Undo Approval']">
                <i class="fa fa-undo" matTooltip="{{ 'tooltips.Undo Approve' | translate}}"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Activate']">
                <i class="fa fa-power-off" matTooltip="{{ 'tooltips.Activate' | translate}}"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="savingsAccountColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: savingsAccountColumns;" [routerLink]="['../', 'savings-accounts', row.id, 'transactions']"></tr>
      </table>

    </ng-container>

  </ng-container>

</div>
