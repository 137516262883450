<div class="container">

  <mat-card>

    <form [formGroup]="delinquencyRangeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.Classification" | translate}}</mat-label>
            <input matInput required formControlName="classification">
            <mat-error *ngIf="delinquencyRangeForm.controls.classification.hasError('required')">
              {{"labels.inputs.Classification" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Days From" | translate}}</mat-label>
            <input matInput type="number" required formControlName="minimumAgeDays">
            <mat-error *ngIf="delinquencyRangeForm.controls.minimumAgeDays.hasError('required')">
              {{"labels.inputs.Days From" | translate}} {{"labels.commons.is" | translate}} <strong>{{"labels.commons.required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Days Till" | translate}}</mat-label>
            <input matInput type="number" formControlName="maximumAgeDays">
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.Cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!delinquencyRangeForm.valid" *mifosxHasPermission="'UPDATE_DELINQUENCY_RANGE'">{{'labels.buttons.Submit' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
