<div class="mat-typography">

  <h3>{{"labels.heading.Address" | translate}}</h3>

  <div fxLayout="row" fxLayoutAlign="flex-end" class="m-b-20">
    <button mat-raised-button color="primary" (click)="addAddress()">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>{{"labels.buttons.Add" | translate}}
    </button>
  </div>

  <mat-accordion *ngIf="clientAddressData.length > 0">

    <mat-expansion-panel *ngFor="let address of clientAddressData; index as i" class="address">

      <mat-expansion-panel-header>

        <mat-panel-title>
          {{ getSelectedValue('addressTypeIdOptions', address.addressTypeId)?.name }}
        </mat-panel-title>

        <mat-panel-description>
          {{ address.relationship }}
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <div class="address-actions" fxLayout="row" fxLayoutAlign="flex-end center">
        <button mat-button color="primary" (click)="editAddress(address,i)">
          <fa-icon icon="edit"></fa-icon>
        </button>
        <button mat-button color="warn" (click)="deleteAddress(address,i)">
          <fa-icon icon="trash"></fa-icon>
        </button>
        <mat-slide-toggle [checked]="address.isActive" (change)="toggleAddress(address)"></mat-slide-toggle>
      </div>

      <p>
        <span *ngIf="isFieldEnabled('street')">{{"labels.inputs.Street" | translate}} : {{address.street}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine1')">{{"labels.inputs.Address Line" | translate}} 1 : {{address.addressLine1}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine2')">{{"labels.inputs.Address Line" | translate}} 2 : {{address.addressLine2}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine3')">{{"labels.inputs.Address Line" | translate}} 3 : {{address.addressLine3}}<br /></span>
        <span *ngIf="isFieldEnabled('townVillage')">{{"labels.inputs.Town / Village" | translate}} : {{address.townVillage}}<br /></span>
        <span *ngIf="isFieldEnabled('city')">{{"labels.inputs.City" | translate}} : {{address.city}}<br /></span>
        <span *ngIf="isFieldEnabled('stateProvinceId')">{{"labels.inputs.State / Province" | translate}} :
          {{getSelectedValue('stateProvinceIdOptions',address.stateProvinceId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('countryId')">{{"labels.inputs.Country" | translate}} :
          {{getSelectedValue('countryIdOptions',address.countryId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('postalCode')">{{"labels.inputs.Postal Code" | translate}} : {{address.postalCode}}<br /></span>
        <span *ngIf="isFieldEnabled('isActive')">{{"labels.inputs.Active Status" | translate}} : {{address.isActive}}<br /></span>
      </p>

    </mat-expansion-panel>

  </mat-accordion>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      {{"labels.buttons.Previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.Next" | translate}}
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</div>
