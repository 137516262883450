<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_TELLER'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{ 'labels.buttons.Edit' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="deleteTeller()" *mifosxHasPermission="'DELETE_TELLER'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{ 'labels.buttons.Delete' | translate }}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{ 'labels.inputs.Teller Name' | translate }}
        </div>

        <div fxFlex="50%">
          {{ tellerData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{ 'labels.inputs.Office' | translate }}
        </div>

        <div fxFlex="50%">
          {{ tellerData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="tellerData.description">
          {{ 'labels.inputs.Description' | translate }}
        </div>

        <div fxFlex="50%" *ngIf="tellerData.description">
          {{ tellerData.description }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{ 'labels.inputs.Start Date' | translate }}
        </div>

        <div fxFlex="50%">
          {{ tellerData.startDate | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="tellerData.endDate">
          {{ 'labels.inputs.End Date' | translate }}
        </div>

        <div fxFlex="50%" *ngIf="tellerData.endDate">
          {{ tellerData.endDate | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{ 'labels.inputs.Status' | translate }}
        </div>

        <div fxFlex="50%">
          {{ tellerData.status | titlecase }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
