<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
<div #buttonCreateShareProduct class="in-block">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_SHAREPRODUCT'">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    {{ 'labels.buttons.Create Share Product' | translate }}
  </button>
</div>
</div>

<div class="container">

  <div #filter fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #shareProductsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Name' | translate }} </th>
        <td mat-cell *matCellDef="let shareProduct"> {{ shareProduct.name }} </td>
      </ng-container>

      <ng-container matColumnDef="shortName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Short Name' | translate }} </th>
        <td mat-cell *matCellDef="let shareProduct"> {{ shareProduct.shortName }} </td>
      </ng-container>

      <ng-container matColumnDef="totalShares">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.inputs.Total Shares' | translate }} </th>
        <td mat-cell *matCellDef="let shareProduct"> {{ shareProduct.totalShares }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateShareProduct let-popover="popover">
  <h2>{{ 'labels.buttons.Create Share Product' | translate }}</h2>
  <p class="mw300">{{'labels.text.This option allows you to create new share product.' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{ 'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{ 'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{ 'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{'labels.heading.Search bar to filter share products.' | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{ 'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateShareProduct,buttonCreateShareProduct , 'bottom', true)">{{ 'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateShareProductsTable,shareProductsTable, 'top', true)">{{ 'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateShareProductsTable let-popover="popover">
  <h4 class="mw300">{{'labels.heading.List of share products in the organization' | translate}}: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/129531946/Share+products" target="_blank">{{ 'labels.buttons.Share Products' | translate }}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{ 'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{ 'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{ 'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>
