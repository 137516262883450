<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_DATATABLE'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    {{'labels.buttons.Edit' | translate }}
  </button>

  <button mat-raised-button color="warn" (click)="delete()" *mifosxHasPermission="'DELETE_DATATABLE'">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    {{'labels.buttons.Delete' | translate }}
  </button>

</div>

<div class="container m-b-15" fxLayoutAlign="start">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px">
        <div class="mat-body-strong">
          {{'labels.inputs.Associated With' | translate}}
        </div>
        <div>
          {{ dataTableData.applicationTableName }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>

<div class="container mat-elevation-z8">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="columnName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Field Name' | translate}} </th>
      <td mat-cell *matCellDef="let dataTableColumn"> {{ dataTableColumn.columnName }}</td>
    </ng-container>

    <ng-container matColumnDef="columnDisplayType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Type' | translate}} </th>
      <td mat-cell *matCellDef="let dataTable"> {{ dataTable.columnDisplayType }} </td>
    </ng-container>

    <ng-container matColumnDef="columnLength">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Length' | translate}} </th>
      <td mat-cell *matCellDef="let dataTable"> {{ dataTable.columnLength }} </td>
    </ng-container>

    <ng-container matColumnDef="columnCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{ 'labels.inputs.Code' | translate }} </th>
      <td mat-cell *matCellDef="let dataTable"> {{ dataTable.columnCode }} </td>
    </ng-container>

    <ng-container matColumnDef="isColumnNullable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.text.Mandatory' | translate}} </th>
      <td mat-cell *matCellDef="let dataTable">
        <fa-icon *ngIf="!dataTable.isColumnNullable" icon="check-circle" size="lg" class="column-mandatory"
          matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
        <fa-icon *ngIf="dataTable.isColumnNullable" icon="times-circle" size="lg" class="not-column-mandatory"
          matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="isColumnUnique">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Unique' | translate}} </th>
      <td mat-cell *matCellDef="let dataTable">
        <fa-icon *ngIf="dataTable.isColumnUnique" icon="check-circle" size="lg" class="column-mandatory"
          matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
        <fa-icon *ngIf="!dataTable.isColumnUnique" icon="times-circle" size="lg" class="not-column-mandatory"
          matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="isColumnIndexed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> {{'labels.inputs.Indexed' | translate}} </th>
      <td mat-cell *matCellDef="let dataTable">
        <fa-icon *ngIf="dataTable.isColumnIndexed" icon="check-circle" size="lg" class="column-mandatory"
          matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
        <fa-icon *ngIf="!dataTable.isColumnIndexed" icon="times-circle" size="lg" class="not-column-mandatory"
          matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

</div>
