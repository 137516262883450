<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <div #buttonImportEmployees>
    <button mat-raised-button color="primary" *mifosxHasPermission="'CREATE_STAFF'" [routerLink]="['/', 'organization', 'bulk-import', 'Employees']">
    <fa-icon icon="upload" class="m-r-10"></fa-icon>
    {{'labels.buttons.Import Employees' | translate }}
  </button>
  </div>
  <div #buttonCreateEmployee>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_STAFF'">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    {{'labels.buttons.Create Employee' | translate }}
  </button>
  </div>

</div>

<div class="container">

    <div #filter fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex>
            <mat-label>{{'labels.inputs.Filter' | translate}}</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>

    <div #tableEmployees class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.name' | translate }} </th>
                <td mat-cell *matCellDef="let employee"> {{ employee.displayName }} </td>
            </ng-container>

            <ng-container matColumnDef="isLoanOfficer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Loan Officer' | translate }} </th>
                <td mat-cell *matCellDef="let employee">
                    <div [className]="employee.isLoanOfficer === true ? 'true' : 'false'">
                        <fa-icon *ngIf="employee.isLoanOfficer" icon="check-circle" size="lg" matTooltip="{{ 'tooltips.Yes' | translate }}" matTooltipPosition="right"></fa-icon>
                        <fa-icon *ngIf="!employee.isLoanOfficer" icon="times-circle" size="lg" matTooltip="{{ 'tooltips.No' | translate }}" matTooltipPosition="right"></fa-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="officeName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Office' | translate }} </th>
                <td mat-cell *matCellDef="let employees"> {{ employees.officeName }} </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.Status' | translate }} </th>
                <td mat-cell *matCellDef="let employee">
                    <div [className]="employee.isActive === true ? 'true' : 'false'">
                        <fa-icon matTooltip="{{ employee.isActive === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

</div>

<ng-template #templateButtonImportEmployees let-data let-popover="popover">
  <h2>{{ 'labels.heading.Import Employees' | translate }}</h2>
  <p class="mw300">{{'labels.text.Import employee details from your local device' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateEmployee,buttonCreateEmployee,'bottom', true)">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateButtonCreateEmployee let-popover="popover">
  <h2>{{ 'labels.heading.Create Employee' | translate }}</h2>
  <p class="mw300">{{'labels.text.Create new employees in your organization' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonImportEmployees,buttonImportEmployees,'bottom', true)">{{'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter,'bottom', true)">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{ 'labels.heading.Search bar to filter employees.' | translate }}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateEmployee,buttonCreateEmployee,'bottom', true)">{{'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateTableEmployees,tableEmployees,'top', true)">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateTableEmployees let-popover="popover">
  <h4>{{ 'labels.heading.List of employees in the organization.' | translate }}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter,'bottom', true)">{{'labels.buttons.Back' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.Next' | translate }}</button>
  </div>
</ng-template>
