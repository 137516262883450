<div class="container m-b-20 space-top" fxLayout="row" fxLayoutGap="20px">
  <div #cobCatchUpStatus>
    <h2 class="no-m">{{'labels.heading.COB Catch-Up is' | translate}}:<span class="m-l-20 m-r-20">{{ isCatchUpRunning ? ( 'labels.catalogs.Running' | translate ) : ('labels.catalogs.Inactive' | translate)}}</span>
    </h2>
  </div>
  <button mat-raised-button class="activate" (click)="runCatchUp()" *ngIf="!isCatchUpRunning">
    <fa-icon icon="times-circle" class="m-r-10"></fa-icon>
    {{'labels.buttons.Run Catch-Up' | translate}}
  </button>
</div>

<div class="container space-top">
  <mifosx-loan-locked></mifosx-loan-locked>
</div>
